import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Responsive, Loader, Divider, Pagination,Confirm, Table, Popup, Label } from 'semantic-ui-react';
import { removeFromFavorites, getFavoriteAllDetails } from './actions';
import { addProduct, createNAddCart, classicAddProduct, classicCreateNAddCart } from '../Cart/actions';
import renderImages from '../common/RenderImages';
import search_img from '../images/Search.svg';
import ProductsCard from './ProductsCard';
import ProductsListCard from './ProductsListCard';
import ProductClassic from './ProductsClassic';
import { MaskPrice } from '../common/MaskPrice';
import ExcelExport from '../common/ExcelExport';
import BusinessRules from '../common/BusinessRules';
import InternalServerError from '../../views/ErrorPages/500_error_page';
import { nonStockMessage, MOMENTUM } from '../common/constants';
import './favorites.css';
import { addToCart, validateProductQuantity, hideToastMessage, classicFavHandleChangeblur,
  getClassicViewPayload, classicAddToCart, handleChange } from '../common/productHandler';
import AddToCartModalClassicView from '../Cart/AddToCartModalClassicView'
import AddToCartModal from '../Cart/AddToCartModal'
import classnames from 'classnames';
import utilities, {getPrefernceType, shouldHideCostForPreference, shouldHideSUGG_RETAILForPreference} from '../../utils/utilities';
import BreadcrumbComp from '../../components/BreadcrumbComp';
import _ from 'lodash';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';

class Favorites extends React.Component {
  constructor(props) {
    super(props);
    this.breadcrumbLinks = [
      { link: '/home', text: 'HOME' },
      { link: '/favorites', text: 'FAVORITES' }
    ];
    this.state = {
      favorites: props.favorites.map(fav => ({ ...fav, selected: false })),
      favoriteItemNums: props.favorites.map(({ itemNbr }) => itemNbr),
      selectedForRemoval: [],
      listView:
        this.props.getprefer &&
        this.props.getprefer?.prdctListlayoutFormat &&
        this.props.getprefer?.prdctListlayoutFormat === 'G' ? 'grid'
        : this.props.getprefer?.prdctListlayoutFormat === 'L' ? 'list'
        : this.props.getprefer?.prdctListlayoutFormat === 'C' ? 'classic' :'list',
      img_txt_to_display:
        this.props.getprefer &&
        this.props.getprefer.thumbnailImage &&
        this.props.getprefer.thumbnailImage !== 'Y'
          ? 'Show Images'
          : 'Hide Images',
      isLoading: props.isLoader,
      serverError: false,
      selectedDepartment: '',
      departments: [],
      totalItems: 0,
      firstItem: 0,
      lastItem: 0,
      currentPage: 1,
      totalPages: 0,
      totalItemsToShow: 50,
      resultPerPage: 0,
      deletionConfirmOpen: false,
      dataSetValue:[],
      inputFocus: [],
      overrideRetail: '',
      quantities: [],
      unselectAll: false,
      isMomentum: (this.props.match?.path === MOMENTUM) ? true : false,
      visibleAddCart: true,
      searchPage:''
    };
    this.pageSize = '&ResultsPerPage=50';
    this.page = '';
    this.department = [];
    this.sortBy = 'ItemNbr';
    this.sortOrder = 'ASC';
    this.addToCart = addToCart.bind(this);
    this.validateProductQuantity = validateProductQuantity.bind(this);
    this.hideToastMessage = hideToastMessage.bind(this);
    this.pathname = '';
    this.scrollPosition = 0;
    this.classicFavHandleChangeblur = classicFavHandleChangeblur.bind(this);
    this.getClassicViewPayload = getClassicViewPayload.bind(this);
    this.classicAddToCart = classicAddToCart.bind(this)
    this.itemRef ={}
  }

  componentDidUpdate() {
    if (this.state.toastMessage) {
      this.hideToastMessage();
    }
  }

  getSuggestedRetail = (product) => {
    const { viewType, multiStoreDetails,getprefer } = this.props;
    let price = BusinessRules.suggestedRetailPrice(product,product.variableRetail,viewType, multiStoreDetails);
    return (
        <MaskPrice
          getprefer={getprefer &&
            this.props.getprefer.customerView
            ? this.props.getprefer.customerView
            : 'c1'  
          }
          viewType={viewType}
          fieldVal={price}
        />)
  }

  fetchData = () => {
    this.sortBy = this.sortBy ? this.sortBy : 'ASC';
    this.page = this.page ? this.page : '1';
    this.department = this.department.length > 0 ? this.department : [];
    this.props.getFavoriteAllDetails(
      this.page,
      this.pageSize,
      this.department,
      this.sortBy,
      this.sortOrder,
      this.scrollPosition,
      this.state.isMomentum
    );
    this.setBackButtonPath();      
  };

  componentDidMount() {
    this.page = 1;
    this.pageSize =
      this.props.getprefer && this.props.getprefer.resultPerPage
        ? this.props.getprefer.resultPerPage
        : '50';
    this.setState({
      favorites: this.props.favorites.map(fav => ({ ...fav, selected: false }))
    })
    const qtyAvailableItem = this.state.favorites.filter(product => !utilities.isEmptyOrNullString(product.cart_available))
   
    const quantities = this.state.favorites
          ? this.state.favorites.map(product => {
            return product.cart_available !== null
              ? product.cart_available
              : ''
          })
          : [];                
      this.setState({
        quantities: quantities,
        visibleAddCart: utilities.isQuantityAvaliable(quantities)
      }
      );
      const dataSetValue = qtyAvailableItem && qtyAvailableItem.map(ele=> {
      let product = {
        item: ele.itemNbr,
        qty: ele.cart_available,
        overrideRetail: utilities.isEmptyOrNullString(ele.cart_overrideretail) ? '' : ele.cart_overrideretail,
        productItem: ele
      }
      return product
    })
    this.setState({dataSetValue: dataSetValue})
    let queryParam = window.location.href.split('?');
    this.pathname = this.props.location && this.props.location.pathname;
    if(queryParam[1]) {
      this.getUrlFilters(queryParam);
    }
    else{
      this.fetchData();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEmpty(nextProps.pagination)&&(this.props.favorites !== nextProps.favorites ||
        this.props.isLoader !== nextProps.isLoader))   {
        let favorites = nextProps.favorites.map(fav => ({
          ...fav,
          selected: false
        }));
      let favoriteItemNums = favorites.map(({ itemNbr }) => itemNbr);
      let lastItem = nextProps.pagination[0].lastItem;
      let totalPages = nextProps.pagination[0].totalPages;
      let totalItems = nextProps.pagination[0].totalItems;
      let firstItem = nextProps.pagination[0].firstItem;
      let currentPage = nextProps.pagination[0].currentPage;
      this.setState({
        favorites,
        favoriteItemNums,
        totalItems,
        firstItem,
        lastItem,
        totalPages,
        selected: false,
        currentPage,
        isLoading: nextProps.isLoader,
        departments: nextProps.filtersDepartment[0].valueList
      });
    }    
  }

  closeConfirmation = () => {
    this.setState({
      deletionConfirmOpen: false
    });
  }

  showHideImages = evt => {
    let img_txt_to_display =
      evt.target.innerHTML === 'Show Images' ? 'Hide Images' : 'Show Images';
    this.setState({ img_txt_to_display: img_txt_to_display });
  };

  setScrollPosition = () => {
    this.scrollPosition = window.pageYOffset;
    this.setBackButtonPath();
  }
  overrideRetailChange = (value) => {
    this.setState({overrideRetail: value})
  }

  setBackButtonPath = () => {
    let pathName = '';     
    pathName = utilities.isEmptyOrNullString(this.page) ? 'Page=1' : 'Page='+this.page;
    pathName = pathName + '&ResultsPerPage='+this.pageSize;

    if (this.sortBy && this.sortBy.length > 0) {
      pathName = pathName + '&SortParam='+this.sortBy;
      if (this.sortOrder && this.sortOrder.length > 0) {
        pathName = pathName + '&SortType='+this.sortOrder;
      } else {
        pathName = pathName + '&SortType=ASC';
      }
    }
    pathName = pathName + '&scrollPosition='+this.scrollPosition;
    if (this.department.length > 0) {
      pathName = pathName + '&filterString='+ this.department;
    }
    let pushUrl = this.pathname +'?'+pathName;
    window.history.pushState(window.history.state, "", pushUrl);
  }

  getUrlFilters = (queryParam) => {
    let getParams = queryParam[1].split('&');
    this.page = getParams[0].split('=')[1];
    this.pageSize =  getParams[1].split('=')[1];
    this.sortBy = queryParam[1].includes('&SortParam=') ? queryParam[1].split('SortParam=')[1].split('&')[0] : '';
    this.sortOrder = queryParam[1].includes('SortType=ASC') ? 'ASC' : 
                          queryParam[1].includes('SortType=DESC') ? 'DESC' : '';
    this.scrollPosition = queryParam[1].split('scrollPosition=')[1].split('&')[0];
    this.department = queryParam[1].includes('filterString') ? [queryParam[1].split('filterString=')[1]]: [''];
    this.fetchData();
  }

  changeDepartment = e => {
    const { value } = e.target;
    this.department = value === '' ? [] : [e.target.value];
    this.setState({ selectedDepartment: value});
    this.scrollPosition = window.pageYOffset;
    this.page = '1';
    this.fetchData();
  };

  onShowQuantityChange = e => {
    this.pageSize = e.target.value;
    this.page = '1';
    this.scrollPosition = window.pageYOffset;
    this.fetchData();
  };

  onPageChange = (e) => {
    this.setState({
      currentPage: this.state.searchPage
    });
    if(this.state.searchPage !== '' && Number(this.state.searchPage) > Number(this.state.totalPages)){
      utilities.showToast(`Please enter the page is less than ${this.state.totalPages}. The page is not available.`, true,'centerToast',9000);
      return;
    }
    let queryPage = e.currentTarget?.text || this.state.searchPage;
    if (e.currentTarget.text === 'Next') {
      queryPage =
        this.state.currentPage === this.state.totalPages - 1
          ? this.state.currentPage
          : parseInt(this.state.currentPage) + 1;
    }
    if (e.currentTarget.text === 'Prev') {
      queryPage = this.state.currentPage === 1 ? 1 : this.state.currentPage - 1;
    }
    if (e.currentTarget.text === '»') {
      queryPage = this.state.totalPages;
    }
    if (e.currentTarget.text === '«') {
      queryPage = 1;
    }
    this.setState({
      searchPage:""
    })
    this.page = queryPage;
    this.scrollPosition = window.pageYOffset;
    this.fetchData();
  };
  onSearchPageHandle = (e) => {
    this.setState({
       searchPage:e.target.value
    })
   };
  selectForRemoval = itemId => {
    this.scrollPosition = window.pageYOffset;
    let productsData = this.state.favorites.map(product => {
      if (itemId === '*') {
        if(!this.state.unselectAll){
          return { ...product, selected: true };
        } else {
          return { ...product, selected: false };
        }
      }
      return product.itemNbr === itemId
        ? { ...product, selected: !product.selected }
        : product;
    });
    let selectedProducts = productsData.filter(product => {
      return product.selected === true;
    });
    this.setState({
      favorites: productsData,
      selectedForRemoval: selectedProducts,
      unselectAll:selectedProducts.length >0? true: false
    });
  };

  deleteFavorite = () => {
    this.scrollPosition = window.pageYOffset;
    const itemNbrs = [];
    this.state.selectedForRemoval.forEach(product => {
      itemNbrs.push(product.itemNbr);
    });
    if(parseInt(this.state.totalItems) === 1 || parseInt(this.state.totalItems) === itemNbrs.length)
    {
      this.department = [];
      this.setBackButtonPath();
    }
    this.setState({
      isLoading: true,
      deletionConfirmOpen: false,
      selectedForRemoval: [],
      unselectAll:itemNbrs.length >0? false: true
    },
    () => {
      this.props.removeFromFavorites(
        itemNbrs,
        this.page,
        this.pageSize,
        this.department,
        this.sortBy,
        this.sortOrder,
        true
      );
    }
  );
}

  removeFavorite = () => {
    if (this.state.selectedForRemoval && this.state.selectedForRemoval.length) {
      this.setState({
        deletionConfirmOpen: true,
        noOfFavoriteToDelete : this.state.selectedForRemoval.length
      });
    }
    else {
      utilities.showToast('Please select any favorite items', true);
    }
  }
  toggleView = choice => {
    this.setState({ listView: choice });
    return this.choice;
  };

  changePageSize = e => {
    this.scrollPosition = window.pageYOffset;
    this.pageSize = '&ResultsPerPage=' + e.currentTarget.value;
    this.page = '&Page=1';
    this.fetchProductsForCategory();
  };

  handleChange = e => {
    const type = e.target.dataset.field;
    const { dataset, value } = e.target;
    const { field, itemNumber } = dataset;
    const fieldValue = parseFloat(value);
    let { quantities } = this.state;
    if (type === 'qty') {
      quantities[itemNumber] = fieldValue || '';
    }
    this.setState({
      quantities: [...quantities],
      visibleAddCart: utilities.isQuantityAvaliable(quantities)
    }
    );
    const value1 = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (type === 'qty' && !/^(\d+)$|^$/.test(value1)) {
      e.preventDefault();
    }
    if (type === 'or' && !/^(\d{0,5}\.\d{0,2}|\d{1,5})$/.test(value1)) {
      e.preventDefault();
    }
  };

  openCartModal = (product, qty, or) => {
    this.setState({
      productSelectedForModal: product,
      isAddToCartModalOpen: true,
      qtyForModal: qty,
      orForModal: or
    });
  };

  closeCartModal = () => {
    this.setState({ isAddToCartModalOpen: false });
  };

  classicHandleAddToCart = () => {
    let loop = true;
    let error = false;
    loop && this.state.inputFocus.length > 0 && this.state.inputFocus.forEach((element,index)=> {
      const product = this.props.favorites.find(ele => ele.itemNbr === element )
      if (loop && !this.validateProductQuantity(product.sPackType, product.sPackQty, this.state.quantities[index], product.maxQty)) {
        this.itemRef[product.itemNbr].current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        this.itemRef[product.itemNbr].current.focus();       
        }
        error = true;
        loop = false
    })
    if(error) {
      return
    }
    if (
      this.props.currentCartType !== 'REGULAR' &&
      this.props.whCarts.length > 0
    ) {
      const payLoads = this.getClassicViewPayload()
      this.openCartModal(payLoads);
    } else if (!this.props.currentCartId || this.props.whCarts.length === 0) {
      const payLoads = this.getClassicViewPayload()
        this.classicAddToCart(payLoads, 'new')

    } else {
      const payLoads = this.getClassicViewPayload()
        this.classicAddToCart(payLoads)

    }

  }
  handleKeyDown = (e)=>{
    if(e.key === 'Enter' && !this.props.isReunionOnlyStore){
      this.classicHandleAddToCart();
    }
  }

  handleAddToCart = (itemNumber) => {
    const product = this.props.favorites.find(
      ({ itemNbr }) => parseInt(itemNbr) === parseInt(itemNumber)
    );
    const qty = document.getElementById('qty-box' + itemNumber).value;
    if (!utilities.isValidQuantity(qty)) {
      this.setState({
        toastMessage: 'Please enter a valid quantity',
        toastError: true
      });
      return;
    }
    if (product.maxQty && qty > product.maxQty) {
      this.setState({
        toastMessage: `Maximum Order quantity is ${product.maxQty}`,
        toastError: true
      });
      return;
    }
    const or = document.getElementById('or-' + itemNumber).value;
    const rdcNum = utilities.rdcBasedonCentralship(product.rdcType, product.CsRdc, this.props.rdc);
    if (
      this.props.currentCartType !== 'REGULAR' &&
      this.props.whCarts.length > 0
    ) {
      this.openCartModal(product, qty, or);
    } else if (product.StockStatusMessage && product.StockStatusMessage.toLowerCase() == 'not stocked') {
      utilities.showToast(nonStockMessage, true);
      return;
    } else if (!this.props.currentCartId || this.props.whCarts.length === 0) {
      this.addToCart(
        product.itemNbr,
        product.memberCost,
        qty,
        or,
        product.sPackType,
        product.sPackQty,
        'new',
        '',
        null,
        product.maxQty
      );
    } else {
      this.addToCart(
        product.itemNbr,
        product.memberCost,
        qty,
        or,
        product.sPackType,
        product.sPackQty,
        null,
        '',
        null,
        product.maxQty
      );
    }
  };

  setSortBy = e => {
    this.sortBy = e.target.value;
    if(this.state.listView === 'classic') {
      this.sortOrder = this.sortOrder === 'ASC' ? 'DESC' : 'ASC' 
    }
    this.page = '1';
    this.fetchData();
  };

  setSortOrder = e => {
    this.sortOrder = e.target.value;
    this.page = '1';
    this.fetchData();
  };
  
  closeMultistoreModal=()=>{
    //this.fetchData();
  }
  classicViewHeader = () => {
    const getPrefer =  getPrefernceType(this.props); 
    const displayMemberCostAndLbl =  shouldHideCostForPreference(this.props.viewType,getPrefer);
    const showSuggRetailPrice = shouldHideSUGG_RETAILForPreference(this.props.viewType,getPrefer);
    return(
    <Table className="favClassicTable" id="classViewTable" style={{marginBottom:0}}>
          <Table.Header>
          <Table.Row>
            <Table.Cell style={{width:'3%'}}></Table.Cell> 
            <Table.Cell style={{width: "10%" }}><button value = 'description' className="titleCell" onClick={this.setSortBy}>Description</button></Table.Cell>
            <Table.Cell style={{width: "12%" }}> <button value = 'vendor_name' className="titleCell" onClick={this.setSortBy}>Vendor</button></Table.Cell>   
            {this.props.multiStoreDetails.access !== 1 || (this.props.multiStoreDetails.access === 1  && this.props.multiStoreDetails.CatMbr) ? 
                  (
                    <>
                      <Table.Cell className='First_level_details' style={{width: "5%" }}>
                      <button value = 'ItemNbr' className="titleCell" onClick={this.setSortBy}>ITEM</button>
                      </Table.Cell>    
                      <Table.Cell className='First_level_details' style={{width: "4%" }}>
                      <button value = 'Model' className="titleCell" onClick={this.setSortBy}>MODEL</button>
                      </Table.Cell>  
                      { displayMemberCostAndLbl && <Table.Cell className='First_level_details' style={{width: "5%"}}>
                        <span>{utilities.getLabelsByType(this.props.viewType,"MEMBER_COST")}</span>
                      </Table.Cell>
                      }
                      { showSuggRetailPrice &&
                      <Table.Cell className='First_level_details' style={{width: "5%"}}>
                        <span>{utilities.getLabelsByType(this.props.viewType,"SUGG_RETAIL",getPrefer)}</span>
                      </Table.Cell>
                      }
                     {this.props.multiStoreDetails && !utilities.isEmptyOrNullString(this.props.multiStoreDetails.aliasSKURetailerGrpID) && 
                      <>
                      <Table.Cell className='First_level_details' style={{width: "5%" }}>
                        <span>Alternate SKU</span>
                      </Table.Cell>
                     </>}
                    
                    </>
                    )
                    :
                    (
                      <>
                          <Table.Cell className='First_level_details' style={{width: "5%" }}>
                            <span>ITEM</span>
                          </Table.Cell>
                          <Table.Cell className='First_level_details' style={{width: "4%" }}>
                            <span>MODEL</span>
                          </Table.Cell>
                        {this.props.multiStoreDetails && !utilities.isEmptyOrNullString(this.props.multiStoreDetails.aliasSKURetailerGrpID) &&            
                            <Table.Cell className='First_level_details' style={{width: "5%" }}>
                              <span>Alternate SKU</span>
                            </Table.Cell>
                          }
                        </>
                       ) 
                    }                   
                      <Table.Cell className='availableFavourite' style={{width: "5%" }}>
                        AVAILABLE
                      </Table.Cell>
                      <Table.Cell className='First_level_details' style={{width: "4%" }}>
                        PACK
                      </Table.Cell>
                      <Table.Cell className='First_level_details' style={{width: "4%" }}>
                        Max Qty
                      </Table.Cell>
                      { this.state.isMomentum && <Table.Cell className='First_level_details' style={{width: "5%" }}>
                        Momentum Code
                      </Table.Cell>}
                      <Table.Cell className='First_level_details' style={{width: "5%" }}>
                        OVERRIDE RETAIL
                      </Table.Cell>
                      <Table.Cell className='First_level_details' style={{width: "5%" }}>
                        QUANTITY
                      </Table.Cell>
                      { !this.state.isMomentum && <Table.Cell className='First_level_details' style={{width: "3%" }}>
                        Remove
                      </Table.Cell>}
                  </Table.Row>
            </Table.Header>
          </Table>
    )
  }
  render() {
    const { toastError, toastMessage,deletionConfirmOpen,noOfFavoriteToDelete,img_txt_to_display} = this.state;
    const img_style = img_txt_to_display === 'Hide Images' ? 'block' : 'none';
    let msCheck = utilities.msExist(this.props.multiStoreDetails); 
    const popupStyle = {fontWeight: 100, fontSize: '10px'}
    return (
      <div className='content-wrap'>
        {this.state.isLoading ? (
          <div>
            <Loader active />
          </div>
        ) : this.state.serverError ? (
          <InternalServerError />
        ) : (
          <>
          {this.state.listView === 'classic' && window.innerWidth >= 1024 ? (
            <AddToCartModalClassicView
              isAddtoCartModalopen={this.state.isAddToCartModalOpen}
              closeCartModal={this.closeCartModal}
              addToCart={this.classicAddToCart}
              product={this.state.productSelectedForModal}
            ></AddToCartModalClassicView>): 
            <AddToCartModal
              isAddtoCartModalopen={this.state.isAddToCartModalOpen}
               closeCartModal={this.closeCartModal}
               addToCart={this.addToCart}
               product={this.state.productSelectedForModal}
               qty={this.state.qtyForModal}
               or={this.state.orForModal}>
            </AddToCartModal>}
            <div className='fav-header'>
              <div className='page-header'>{this.state.isMomentum ? 'Momentum Items' : 'Favorite Items'}</div>
              { !this.state.isMomentum &&  <span className='fav-select-all-span' onClick={() => this.selectForRemoval('*')}>
              {this.state.unselectAll ? 'Unselect All' : 'Select All'}
              </span>}
            </div>
            <BreadcrumbComp path={this.breadcrumbLinks} />
            <div className='fav-options'>
              <div className='fav-sort-div'>
                <div className='fav-dept-div'>
                  <span className='favoritesDepartment'>
                    <span>Department: </span>
                  </span>
                  <select text='All Departments'
                          value={this.department}
                          onChange={this.changeDepartment}
                          className='select-department'>
                    <option className='SelectOption' value=''>
                      All Departments
                    </option>
                    {Object.keys(this.state.departments).map(key => {
                      return <option value={key}>{this.state.departments[key]}</option>;
                    })}
                  </select>
                </div>
                <div className='fav-showby-div'>
                  <span>
                    <span> Show:  </span>
                  </span>
                  <select value={this.pageSize} className='select-range' onChange={this.onShowQuantityChange}>
                    <option className='SelectOption' value={50}>
                      50
                    </option>
                    <option className='SelectOption' value={100}>
                      100
                    </option>
                  </select>
                </div>
                <div className='fav-sortby-div'>
                  <span>
                    <span>Sort By: </span>
                  </span>
                  <select value={this.sortBy} onChange={this.setSortBy} className='select-item'>
                    <option className='SelectOption' value='ItemNbr'>
                      Item#
                    </option>
                    <option className='SelectOption' value='Model'>
                      Model#
                    </option>
                    <option className='SelectOption' value='description'>
                      Description
                    </option>
                    <option className='SelectOption' value='vendor_name'>
                      Manufacturer
                    </option>
                    {this.state.isMomentum && 
                    <option className='SelectOption' value='MomentumCode'>
                    Momentum Code
                  </option>}
                  </select>
                  <select value={this.sortOrder} onChange={this.setSortOrder} className='select-order'>
                    <option className='SelectOption' value='ASC'>
                      Ascending
                    </option>
                    <option className='SelectOption' value='DESC'>
                      Descending
                    </option>
                  </select>
                </div>
                <div className='fav-right-options'>
                  
                  <Responsive minWidth={1024} className='toggle-view'>
                    <div className='icon_align'>
                      <Popup content='Classic View' className={"iconPopUp"} style ={popupStyle} trigger ={<i
                        className='table icon cursor_p'
                        value='Classic'
                        id='classic_icon'
                        size='mini'
                        onClick={() => this.toggleView('classic')}
                      ></i>} />
                      |
                      <Popup content='List View' style ={popupStyle} trigger ={ <i
                        className='list icon cursor_p'
                        value='List'
                        id='list_icon'
                        size='mini'
                        onClick={() => this.toggleView('list')}
                      ></i>} />
                      |
                      <Popup content='Grid View' style ={popupStyle} trigger ={<i
                        className='th icon cursor_p'
                        value='Grid'
                        id='grid_icon'
                        size='mini'
                        onClick={() => this.toggleView('grid')}
                      ></i> } />
                    </div>
                  </Responsive>
                  {!this.state.isMomentum && <button className='compare-btn' onClick={() =>this.removeFavorite()}>
                    Remove Selected Products
                  </button>}
                  <Confirm
                    className='cart-confirm'
                    open={deletionConfirmOpen}
                    onCancel={this.closeConfirmation}
                    header='Delete Favorite items'
                    content={`Are you sure you want to delete (${noOfFavoriteToDelete}) favorite items?`}
                    onConfirm={this.deleteFavorite}
                  />
                </div>
              </div>
            </div>
            <div className="favorite-hidepage">
              <div className="favorite-lefttp">
                  <span className='pagination-span'>
                    {this.state.firstItem}-{this.state.lastItem} of{' '}
                    {this.state.totalItems} products
                  </span>
                  <span
                    onClick={this.showHideImages}
                    id='show_and_hide_images'
                    className='show_and_hide_imagesfavorite'
                  >
                    {this.state.img_txt_to_display}
                  </span>
                </div>
                {this.state.totalPages > 0 && (
                  <div className='headerPagination'>
                      <Pagination
                        className='pagination paginationMobile'
                        boundaryRange={0}
                        activePage={parseInt(this.state?.currentPage)}
                        totalPages={parseInt(this.state?.totalPages)}
                        onPageChange={this.onPageChange}
                        ellipsisItem={null}
                        siblingRange={1}
                        firstItem={parseInt(this.state?.currentPage) === 1 ? { content: <span className='disabled'>«</span> } : { content: '«' }}
                        lastItem={parseInt(this.state?.currentPage) === parseInt(this.state?.totalPages) ? { content: <span className='disabled'>»</span> } : { content: '»' }}
                        prevItem={parseInt(this.state?.currentPage) === 1 ? { content: <span className='disabled'>Prev</span> } : { content: 'Prev' }}
                        nextItem={parseInt(this.state?.currentPage) === parseInt(this.state?.totalPages) ? { content: <span className='disabled'>Next</span> } : { content: 'Next' }}
                      />
                      <div className='paginationSearch'>
                        <label htmlFor="search_PLPPagination">Enter Page No</label>
                        <div className='inlineGrouptext'>
                          <input
                            id='search_PLPPagination'
                            onChange={this.onSearchPageHandle}
                            value={this.state.searchPage}
                          />
                          <span className="rightLabel"> / {this.state.totalPages}</span>
                        </div>
                        <button className="red ui button paginationButton" onClick={this.onPageChange}>Go</button>
                      </div>
                 </div>
                )}
                {this.state.isMomentum && <ExcelExport
                  fileName='Momentum Items'
                  className='exportPDF exportPdfPLP'
                  sortBy = {this.sortBy}
                  sortOrder = {this.sortOrder}
                  sortDepartment = {this.department}
                  storeId = {this.props.storeId}
                  userId = {this.props.userId}
                  aliasSKURetailerGrpID = {this.props.aliasSKURetailerGrpID}
                  currentCartId = {this.props.currentCartId}
                  multiStoreDetails = {this.props.multiStoreDetails}
                  rdc= {this.props.rdc}
                  totalItems = {this.state.totalItems}
                  getprefer={this.props.getprefer &&
                    this.props.getprefer.customerView
                    ? this.props.getprefer.customerView
                    : 'c1'  
                  }
                  viewType={this.props.viewType}
                />}
            {this.state.listView === 'classic' && window.innerWidth >= 1024 && (
            <div className="classicFavAddBtn" style={{marginTop:0}}>
            {this.props.multiStoreDetails.access !== 1  &&
                      <>                    
                      {msCheck && msCheck > 1 ?
                      !this.props.isReunionOnlyStore ?
                      <button
                      className='red ui button'
                      disabled = {this.state.visibleAddCart}
                      onClick={this.classicHandleAddToCart}
                      style={{ width: '-webkit-fill-available' }}
                      
                    >
                      Add to Store
                    </button>
                    : <>
                        <button className='red ui button' disabled>Add to Store</button>
                        <p class="disabledMessage">*Ordering restricted</p>
                      </>
                    :!this.props.isReunionOnlyStore ?
                    <button
                      className='red ui button full-width'
                      onClick={this.classicHandleAddToCart}
                      disabled = {this.state.visibleAddCart}
                    >
                      Add to Cart
                    </button>
                  : <>
                      <button className='red ui button' disabled>Add to Cart</button>
                      <p class="disabledMessage">*Ordering restricted</p>
                    </>
                    }
                      </>
                    }
            </div>
            )}
            </div>
            <div>
            <div className={this.state.listView === 'list' || this.state.listView === 'classic'
                            ? '': 'product-grid ra-grid grid-columns ui six doubling cards'}>
              {this.state.listView === 'classic' && window.innerWidth >= 1024 && (
                this.classicViewHeader()
              )}
              {this.state.favorites &&
                this.state.favorites.map((product,index) => {
                  this.itemRef[product.itemNbr] = React.createRef()
                  let img2 = product
                    ? 'https://images.truevalue.com/getimage.asp?id=' +
                      product.itemNbr +
                      '&ImageType=2&NoImageType=2&ColorImageSize=2&LineArtImageSize=2'
                    : '';
                  return this.state.listView === 'list' && window.innerWidth >= 1024 ? (
                    <ProductsListCard
                      product={product}
                      indexForModalMs={index}
                      img_style={img_style}
                      addToCart={this.handleAddToCart}
                      source='favorites'
                      selectForRemoval={this.selectForRemoval}
                      imageUrl={img2}
                      viewType={this.props.viewType}
                      setBackButtonPath={this.setScrollPosition}
                      isReunionOnlyStore = {this.props.isReunionOnlyStore}
                      closeMultistoreModal={this.closeMultistoreModal}                 
                      getSuggestedRetail={this.getSuggestedRetail}
                      isMomentum = {this.state.isMomentum}
                    />
                  ) : 
                  this.state.listView === 'classic' && window.innerWidth >= 1024 ? (
                      <ProductClassic 
                      product={product}
                      indexForModalMs={index}
                      img_style={img_style}
                      addToCart={this.handleAddToCart}
                      source='favorites'
                      selectForRemoval={this.selectForRemoval}
                      getClassicViewPayload = {this.getClassicViewPayload}                      
                      imageUrl={img2}
                      viewType={this.props.viewType}
                      setBackButtonPath={this.setScrollPosition}
                      handleChange={this.handleChange}
                      handleChangeblur={this.classicFavHandleChangeblur}
                      quantities ={this.state.quantities}
                      inputFocus = {this.state.inputFocus.includes(parseInt(product.itemNbr))}
                      overrideRetailChange = {this.overrideRetailChange}
                      isReunionOnlyStore = {this.props.isReunionOnlyStore}
                      itemRef = {this.itemRef[product.itemNbr]}
                      handleKeyDown = {this.handleKeyDown}
                      getSuggestedRetail={this.getSuggestedRetail}
                      isMomentum = {this.state.isMomentum}
                       />
                  ) : (
                    <ProductsCard
                      product={product}
                      indexForModalMs={index}
                      source='favorites'
                      addToCart={this.handleAddToCart}
                      img_style={img_style}
                      selectForRemoval={this.selectForRemoval}
                      imageUrl={img2}
                      viewType={this.props.viewType}
                      handleChange={this.handleChange}
                      setBackButtonPath={this.setScrollPosition}
                      isReunionOnlyStore = {this.props.isReunionOnlyStore}
                      closeMultistoreModal= {this.closeMultistoreModal}
                      getSuggestedRetail={this.getSuggestedRetail}
                      isMomentum = {this.state.isMomentum}
                    />
                  );
                })}
            </div>
            </div>

            <Divider />
            {this.state.totalPages > 0 && (
              <div className='fav-footer'>
                <span className='pagination-span'>
                  {this.state.firstItem}-{this.state.lastItem} of{' '}
                  {this.state.totalItems} products
                </span>
                {this.state.totalPages > 0 && (
                  <Pagination
                    className='pagination PaginationMobileBottom'
                    boundaryRange={0}
                    activePage={this.state.currentPage}
                    totalPages={this.state.totalPages}
                    onPageChange={this.onPageChange}
                    ellipsisItem={null}
                    siblingRange={1}
                    firstItem={parseInt(this.state?.currentPage) === 1 ? { content: <span className='disabled'>«</span> } : { content: '«' }}
                    lastItem={parseInt(this.state?.currentPage) === parseInt(this.state?.totalPages) ? { content: <span className='disabled'>»</span> } : { content: '»' }}
                    prevItem={parseInt(this.state?.currentPage) === 1 ? { content: <span className='disabled'>Prev</span> } : { content: 'Prev' }}
                    nextItem={parseInt(this.state?.currentPage) === parseInt(this.state?.totalPages) ? { content: <span className='disabled'>Next</span> } : { content: 'Next' }}
                  />
                )}
                {!this.state.isMomentum && <button className='compare-btn remove-select-product' onClick={() =>this.removeFavorite()}>
                  Remove Selected Products
                </button>}
                <div id='snackbar' className={classnames({red: toastError, show: toastMessage})}>
                  {toastMessage}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    storeId: state.SessionReducer.storeId,
    userId: state.SessionReducer.UserId,
    userName: state?.SessionReducer?.address?.userName,
    favorites: state.FavoritesReducer.favorites,
    isLoader: !state.FavoritesReducer.isFetchComplete,
    pagination: state.FavoritesReducer.pagination,
    filtersDepartment: state.FavoritesReducer.filtersDepartment,
    currentCartId: state?.cart?.orders?.currentCart?.cartId || '',
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    multiStoreDetails : state.SessionReducer.multiStoreDetails,
    currentCartType: state.cart.orders.currentCart.cartInfoName,
    aliasSKURetailerGrpID: state.SessionReducer?.address?.aliasSKURetailerGrpID,
    rdc: state.SessionReducer.rdc,
    whCarts: state.cart.orders.allCarts.filter(
      cart => cart.cartInfoName === 'REGULAR'
    ),
    getprefer: state.preference.listData,
    isReunionOnlyStore: state.SessionReducer.address.isReunionOnlyStore
  };
};

const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators(
      { removeFromFavorites, getFavoriteAllDetails, addProduct, createNAddCart, classicAddProduct, classicCreateNAddCart },
      dispatch
    )
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Favorites);