import React from 'react';
import utilities from '../../utils/utilities';
import {getSelect, getDatePicker, getButton} from '../common/ComponentUtilities';
import restUtils from '../../utils/restUtils';
import {GET_CART_REPORT} from '../../urlConstants';
import InternalServerError from '../../views/ErrorPages/500_error_page';
import {downloadBinary} from '../common/GenericExcelExport/exportUtils';

export const REPORT_TYPES = [
    {
        id: 0,
        value: 'Pending Cart Report'
    }, 
    {
        id: 1,
        value: 'Submitted Cart Report'
    }
];
export const ORDER_TYPES = [
    {
        id: 'RU',
        value: 'Reunion Orders'
    }, 
    {
        id: 'RE',
        value: 'Retail Event Orders'
    },
    {
        id: 'WH',
        value: 'Warehouse Orders'
    },
    {
        id: 'CTB',
        value: 'CTB Orders'
    }
];
export const REUNION_TYPES = [
    {
        id: 'FR',
        value: 'Fall Reunion'
    }, 
    {
        id: 'SR',
        value: 'Sprint Reunion'
    }
];
class CartReport extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            selectedReportType: '',
            selectedOrderType: '',
            selectedReunionType: 'FR',
            selectedDates: {},
            serverError: false,
            disabledButton: true,
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(JSON.stringify(this.state) !== JSON.stringify(prevState)) {
            this.updateDisabledDownload();
        }
    }
    getReportTypeDropDown = ()=>{
        const selectProps = {className: 'combo dropdownWidth', onChange: this.changeReportType}
        let reportTypeOptions = [{ props: { value: '' }, text: '--- Select ---' }];

        REPORT_TYPES.forEach((reportType)=>{
            const eachOpt = { props: { value: reportType.id, id: reportType.id, className: 'SelectOption' }, text: reportType.value }
            reportTypeOptions = [...reportTypeOptions, eachOpt]
        })
        return getSelect(selectProps, reportTypeOptions)
    }
    getReunionTypeDropDown = ()=>{
        const selectProps = {className: 'combo dropdownWidth', style: {'marginLeft': '0.3rem'}, onChange: this.changeReunionType}
        let reportTypeOptions = [];

        REUNION_TYPES.forEach((reportType)=>{
            const eachOpt = { props: { value: reportType.id, id: reportType.id, className: 'SelectOption' }, text: reportType.value, default: 'FR'}
            reportTypeOptions = [...reportTypeOptions, eachOpt]
        })
        return getSelect(selectProps, reportTypeOptions)
    }
    getOrderTypeDropDown = ()=>{
        const selectProps = {className: 'combo dropdownWidth', onChange: this.changeOrderType}
        let orderTypeOptions = [{ props: { value: '' }, text: '--- Select ---' }];

        ORDER_TYPES.forEach((orderType)=>{
            const eachOpt = { props: { value: orderType.id, id: orderType.id, className: 'SelectOption' }, text: orderType.value }
            orderTypeOptions = [...orderTypeOptions, eachOpt]
        })
        return getSelect(selectProps, orderTypeOptions)
    }
    changeReportType = (e)=>{
        this.setState({selectedReportType: e.target.value});
        this.updateDisabledDownload();
    }
    changeReunionType = (e)=>{
        this.setState({selectedReunionType: e.target.value});
        this.updateDisabledDownload();
    }
    changeOrderType = (e)=>{
        this.setState({selectedOrderType: e.target.value});
        this.updateDisabledDownload();
    }
    updateSelectedDate = (id, value)=>{
        if(!value || value.length ===0){
            return;
        }
        if(id === 'startDate' || id === 'endDate'){
            const cleanedValue = utilities.getDateFromTimeStampShip(new Date(value));
            const {selectedDates} = this.state;
            selectedDates[id] = cleanedValue;
            this.setState({selectedDates});
        }
        this.updateDisabledDownload();
    }
    isEndDateValid(startDate, endDate, values) {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const isvalidMonthsorDays = values;
        const maxEndDate = new Date(start);
            if (isvalidMonthsorDays === 2) {
                maxEndDate.setMonth(start.getMonth() + 2);
            }
            else if(isvalidMonthsorDays === 14){
                maxEndDate.setDate(maxEndDate.getDate() + 14);
            }
            else{
                maxEndDate.setDate(maxEndDate.getDate());
            }
            return end <= maxEndDate;
    }
    updateDisabledDownload = ()=>{
        const {selectedReportType, selectedOrderType, selectedDates} = this.state;
        const {startDate, endDate} = selectedDates;
        if(selectedReportType && selectedOrderType && this.isEndDateValid(startDate, endDate,1)){
            this.setState({disabledButton: false});
        }
    }
    componentDidMount(){
        this.updateSelectedDate('startDate', new Date());
        this.updateSelectedDate('endDate', new Date());
        this.updateDisabledDownload();
    }
    datePicker = (fieldName, id, value, fieldClass, isLabel, removeTime)=>{
        return getDatePicker(fieldName, id, value? new Date(value):new Date(), this.updateSelectedDate, undefined, fieldClass, isLabel, removeTime);
    }
    reportTypeLayout = ()=>{
        return(
            <div className = 'upload-link'>
                <div className = 'displayFlex'>
                    Report Type  {this.getReportTypeDropDown()}
                </div>
                <div className = 'displayFlex'>
                        
                </div>
            </div>
        )
    }
    reunionTypeLayout = ()=>{
        return(
            <div className = 'upload-link'>
                <div className = 'displayFlex'>
                    Reunion Type  {this.getReunionTypeDropDown()}
                </div>
                <div className = 'displayFlex'>
                        
                </div>
            </div>
        )
    }
    orderTypeLayout = ()=>{
        return(
            <div className = 'upload-link'>
                <div className = 'displayFlex'>
                    Order Type  &nbsp; {this.getOrderTypeDropDown()}
                </div>
            </div>
        )
    }

    fileNameCheck = () => {
        const {selectedReportType, selectedOrderType} = this.state;
        let fileName = "CartReport"
        if (selectedReportType == 0 && selectedOrderType == "RU"){
            fileName = "ReunionOrder_PendingCartReport";
        } else if (selectedReportType == "1" && selectedOrderType == "RU") {
            fileName = "ReunionOrder_SubmittedCartReport";
        } else if (selectedReportType == "0" && selectedOrderType == "RE") {
            fileName = "RetailEventOrder_PendingCartReport.xlsx";
        } else if (selectedReportType == "1" && selectedOrderType == "RE") {
            fileName = "RetailEventOrder_SubmittedCartReport";
        } else if (selectedReportType == "0" && selectedOrderType == "WH") {
            fileName = "WarehouseOrder_PendingCartReport";
        } else if (selectedReportType == "1" && selectedOrderType == "WH") {
            fileName = "WarehouseOrder_SubmittedCartReport";
        } else if (selectedReportType == "0" && selectedOrderType == "CTB") {
            fileName = "CTB_AssormentOrder_PendingCartReport";
        } else if (selectedReportType == "1" && selectedOrderType == "CTB") {
            fileName = "CTB_AssormentOrder_SubmittedCartReport";
        }
        return fileName;
    }

    handleOnClick = ()=>{
        
        if(this.state.selectedReportType === ''){
            return utilities.showToast('Please Select Report Type');
        }
        if(this.state.selectedOrderType === ''){
            return utilities.showToast('Please Select Order Type');
        }

        const reportType = this.state.selectedReportType
        const orderType = this.state.selectedOrderType
        const reunionType = this.state.selectedReunionType
        const {startDate, endDate} = this.state.selectedDates;
        
        if(startDate > endDate){
           return utilities.showToast('Start Date must less then End Date');
        }
        if(orderType  === 'CTB'){
            if(reportType == 0){
                const dateAfter60Days = this.isEndDateValid(startDate, endDate,2);
                if(!dateAfter60Days){
                    return utilities.showToast('Please select date ranges up to 2 months.');
                }  
            } else {
                const dateAfter14Days = this.isEndDateValid(startDate,endDate, 14);
                if(!dateAfter14Days){
                    return utilities.showToast('Please select date ranges up to 14 Days.');
                 } 
            }  
        }
        
    
        this.setState({isLoading: true});
        let queryParams = `?reportType=${reportType}` + `&orderType=${orderType}` + '&startDate='+encodeURIComponent(startDate) + '&endDate='+encodeURIComponent(endDate);
        if(orderType === 'RU'){
            queryParams = queryParams + `&reunion=${reunionType}`
        }
        const requestUrl  = GET_CART_REPORT + queryParams;
        restUtils.getData(requestUrl).then((response)=>{
            const fileName = this.fileNameCheck();
            downloadBinary(response.data, fileName);
            this.setState({isLoading: false});
        }).catch((error)=>{
            console.log(error);
            utilities.showToast('Download cannot be complete');
            this.setState({isLoading:false});
        });
    }
    dateTypeLayout = (selectedDates, isLoading)=>{
        return (
            <div className = 'upload-link'>
                <div>
                {
                    this.datePicker('Start Date','startDate', selectedDates.startDate, 't-center dropship_datepicker date-format', true, true)
                }
                </div>
                <br></br>
                <div>
                {
                    this.datePicker('End Date', 'endDate', selectedDates.endDate, 't-center dropship_datepicker', true, true)
                }
                </div>
                {getButton({type:'button', className:isLoading?'ui red loading button dropship_button':'ui red button dropship_button', name:'download', onClick: this.handleOnClick, disabled: this.state.disabledButton}, 'Download')}
            </div>
        )
    }
    render(){
        const {isLoading, serverError, selectedDates} = this.state;
        return(
            <>
                {
                    serverError? <InternalServerError/> : (
                        <>
                        <div class="grid-container-cartReport">
                        <div class="grid-item"></div>
                        <div class="grid-item"> 
                            <div className='upload-link'>
                                <div className=' page-header mb-2' style={{textAlign:"center"}}>
                                    Cart Report
                                </div>
                            </div>
                            <div className="cartReportOption" style={{padding:"6px"}}>
                                <div className="cartReportForm">
                                    {this.orderTypeLayout()}
                                    {this.state.selectedOrderType === 'RU' && this.reunionTypeLayout()}
                                    {this.reportTypeLayout()}
                                    {this.dateTypeLayout(selectedDates, isLoading)}
                                </div>
                            </div>
                        </div>
                        <div class="grid-item"></div>  
                        </div>
                        </>
                    )
                }
            </>
        )
    }
}

export default CartReport;
