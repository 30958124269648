import React, { Component } from 'react';
import '../styles.css';
import restUtils from '../../../utils/restUtils';
import { DROPSHIP_VENDOR_LISTING } from '../../../urlConstants';
import { Loader } from 'semantic-ui-react';
import _, { head } from 'lodash';
import utilities from '../../../utils/utilities';
import { DealsDialog } from '../MonthlySpecials/DealsDialog';
import BreadcrumbComp from '../../../components/BreadcrumbComp';
import { getEventRedirectUrl, getPageName } from './eventRedirectUtilities';
import history from '../../../history';
import { connect } from 'react-redux';
import { getVendorDialog } from './ReunionComponentUtilities';
import OptionsSection from "./OptionsSection";
import { isFilterRequired } from "./ReunionComponentUtilities";
import Filter from "./Filter";
import { isArray } from "util";
import { getPagination } from "../../common/CustomComponents";
import {
  isSortRequired,
  isShowByRequired,
  getBreadcrumbLinks,
  getPageNumber
} from "./itemListingUtilities";
import renderImages from '../../common/RenderImages';
import search_img from '../../images/Search.svg';

class VendorListingDropShipDC extends Component {
  constructor(props) {
    super(props);
    const { 
      eventId,
      flowType,
      fromReunionHome,
      promoName,
      relayYear,
      relayNbr,
      shoppingAreaName,
      shoppingId
    } = props.match.params;
    const shoppingAreaMetaId = decodeURIComponent(props?.location?.state?.shoppingAreaMetaId);
    this.state = {
      fromReunionHome : fromReunionHome,
      eventVendorDetails: [],
      relayNbr: relayNbr,
      relayYear : relayYear,
      promoNmbr: eventId,
      flowType: flowType,
      promoName : promoName,
      orderDeadline: '',
      dealDetails: false,
      selectedvendorName: '',
      isLoading: true,
      header: shoppingAreaName,
      shoppingAreaId: shoppingId,
      shoppingAreaMetaId: shoppingAreaMetaId,
      isFilterRequired: isFilterRequired(flowType),
      filters: [],
      isNoProducts: false,
      appliedFilters: {},
      paginationParams: {},
      searchPage:""
    }
    this.filterList = {};
    this.pageSize =
      this?.props?.getprefer?.resultPerPage
        ? this.props.getprefer.resultPerPage
        : "50";
    this.page = 1;
    this.sortDirection = "asc";
    this.sortParam = "vendorname";
    this.pathname = "";
    this.scrollPosition = 0;
  }

  componentDidMount() {
    let queryParam = window.location.href.split('?');
    this.pathname = this.props.location && this.props.location.pathname;
    if(queryParam[1]) {
      this.getUrlFilters(queryParam);
    } else {
      this.getReunionEventVendors();
    }
  }

  showDealDetails(vendorName) {
    this.setState({
      dealDetails: true,
      selectedvendorName: vendorName
    });
  }

  filterCallback = (isAdd, filterKey, filterValue) => {
    let { appliedFilters } = this.state;
    this.scrollPosition = window.pageYOffset;
    let keyExists = appliedFilters.hasOwnProperty(filterKey);
    if (!keyExists && isAdd) {
      appliedFilters = { ...appliedFilters, [filterKey]: [filterValue] };
    } else if (keyExists) {
      let value = appliedFilters[filterKey];
      let index = value.indexOf(filterValue);
      if (isArray(value) && index > -1) {
        value.splice(index, 1); // remove if value exists
      } else {
        value.push(filterValue); // add if value is new
      }
      if (!value.length && keyExists) {
        delete appliedFilters[filterKey];
      } else {
        appliedFilters[filterKey] = value;
      }
    }
    this.filterList = utilities.getFilterListFromObject(appliedFilters);
    this.setState(
      {
        currentPage: 1,
        appliedFilters,
        clearFilters: false,
        backPath: this.pathname,
      },
      () => {
        this.page = 1;
        this.getReunionEventVendors();
      }
    );
  };

  setBackButtonPath = () => {
    let pathName = "";
    pathName = utilities.isEmptyOrNullString(this.page) ? 'Page=1' : 'Page='+this.page;
    pathName = pathName + '&ResultsPerPage='+this.pageSize;
    if (this.sortParam && this.sortParam.length > 0) {
      pathName = pathName + "&SortParam=" + this.sortParam;
      if (this.sortDirection && this.sortDirection.length > 0) {
        pathName = pathName + "&SortType=" + this.sortDirection;
      } else {
        pathName = pathName + "&SortType=asc";
      }
    }
    pathName = pathName + '&scrollPosition='+this.scrollPosition;
    if (!_.isEmpty(this.filterList)) {
      pathName =
        pathName +
        "&filterString=" +
        utilities.getQueryStrForFilterString(this.state.appliedFilters);
    }
    if (pathName !== "") {
      let pushUrl = this.pathname + "?" + pathName;
      window.history.pushState(window.history.state, "", pushUrl);
    }
  };

  getUrlFilters = (queryParam) => {
    let getParams = queryParam[1].split('&');
    this.page = getParams[0].split('=')[1];
    this.pageSize =  getParams[1].split('=')[1];
    this.sortParam = queryParam[1].includes('&SortParam=') ? queryParam[1].split('SortParam=')[1].split('&')[0] : '';
    this.sortDirection = queryParam[1].includes('SortType=asc') ? 'asc' : 
                          queryParam[1].includes('SortType=desc') ? 'desc' : '';    
    this.scrollPosition = queryParam[1].split('scrollPosition=')[1].split('&')[0];
    let filterString = queryParam[1].includes('filterString') ? queryParam[1].split('filterString=')[1] : '';    
    let appliedFilters = utilities.getFilterArrayAsMultiSelectObject(filterString);
    this.filterList = utilities.getFilterListFromObject(appliedFilters);
    this.setState({
      appliedFilters
    },() => {
      this.getReunionEventVendors();
    });
  }

  DialogClose = () => this.setState({ dealDetails: false });

  getReunionEventVendors = () => {
      this.setState({ isloading: true });
      let url = `${DROPSHIP_VENDOR_LISTING}`;
      let requestPayLoad = {
        "promoNbr":  this.state.promoNmbr,
        "sortBy": this.sortParam,
        "sortOrder": this.sortDirection,
        "page": this.page,
        "relayNbr":this.state.relayNbr,
        "relayYear":this.state.relayYear,
        "resultPerPage": this.pageSize,
        ...(!_.isEmpty(this.filterList) ? { FilterBy: this.filterList } : {})
      }
      this.setState({isLoading:true});
      restUtils
        .postData(url, requestPayLoad)
        .then(response => {
          let filters = response?.data?.filters;
          filters.forEach(filter=>{
          if(filter.name==="Vendor Name"){
            filter.valueList = utilities.objSortValues(filter.valueList);
          }
          })
          this.setState({
            eventVendorDetails: response?.data?.dropShipVendorsList ? response.data.dropShipVendorsList : '',
            filters,
            paginationParams: response?.data?.pagination? response.data.pagination[0] : '',
            orderDeadline: response.data?.OrderDeadline ? utilities.getDateFromTimeStamp(response.data.OrderDeadline): '',
            isLoading: false
          });
          this.setBackButtonPath();
        })
        .catch(error => {
          console.log(error);
          this.setState({ isLloading: false, serverError: true });
        });
  }

  onPageChange = (e) => {
    const { paginationParams } = this.state;
    this.scrollPosition = window.pageYOffset;
    if(this.state.searchPage !== '' && Number(this.state.searchPage) > Number(paginationParams.totalPages)){
      utilities.showToast(`Please enter the page is less than ${paginationParams.totalPages}. The page is not available.`, true,'centerToast',9000);
      return;
    }
    let queryPage = e.currentTarget?.text || this.state.searchPage;
    this.page = getPageNumber(queryPage, paginationParams);
    window.scrollTo(0, 0);
    this.getReunionEventVendors();
  };
  onSearchPageHandle = (e) => {
    this.setState({
       searchPage:e.target.value
    })
   };
  showItemList = (e) => {
    const { promoNmbr, header, promoName, shoppingAreaId, shoppingAreaMetaId, relayNbr, flowType, relayYear, 
       fromReunionHome } = this.state;
    const {id, vendorName, shipdate1 ,shipdate2 ,shipdate3, shipdate4 ,memberterms, eventsupplierselectionid} = e.target.dataset;
    const keyParams = {
      eventId: promoNmbr,
      shoppingAreaId: shoppingAreaId,
      shoppingAreaMetaId: shoppingAreaMetaId,
      shoppingAreaName: header,
      flowType,
      vendorId: id ? id : e.target.id,
      vendorName,
      relayNbr,
      relayYear,
      promoName,
      fromReunionHome,
      eventSupplierSelectionID: eventsupplierselectionid,
      pageName: getPageName(flowType)
    };
    history.push({
      pathname: getEventRedirectUrl({ keyParams }),
      state: {
        relayNbr,
        relayYear,
        promoName: promoName,
        shoppingAreaName: header,
        shoppingAreaId,
        shoppingAreaMetaId,
        flowType,
        vendorName,
        vendorId: e.target.id,
        fromVendorPage:true,
        fromReunionHome,
        shipdate1,
        shipdate2,
        shipdate3,
        shipdate4,
        memberterms,
        eventsupplierselectionid
      }
    });
  }

  getVendors = (eventVendorDetails, dealDetails, selectedvendorName, flowType) => {
    if (!this.state.isLoading) {
      if (Array.isArray(eventVendorDetails) && !_.isEmpty(eventVendorDetails)) {
        return (
          <>
            {eventVendorDetails && eventVendorDetails?.map(event => {
              const vendorName = event.vendorName.trim();
              const shipDates = [event.shipDate1, event.shipDate2, event.shipDate3, event.shipDate4];
              const dealsDialogParams = {
                dealDetails: dealDetails,
                selectedvendorName: selectedvendorName,
                num: event.num,
                vendorName: vendorName,
                member_terms: event.memberTerms,
                shipDates: shipDates
              }
              const vendorTitle = <div className="reunionVendorName"
                id={event.vendorID}
                data-vendor-name={vendorName}
                data-memberterms={event.memberTerms}
                data-shipdate1={event.shipDate1}
                data-shipdate2={event.shipDate2}
                data-shipdate3={event.shipDate3}
                data-shipdate4={event.shipDate4}
                >
                {vendorName}
              </div>;
              const vendorDetailsParams = {
                vendorTitle: vendorTitle,
                vendorName: vendorName,
                flowType: flowType,
                vendorID: event.vendorID,
                vendorInfo: event.vendorContactInfo
              }
              return (
                <div className="column">
                  <div className="reunionVendorCard" >
                    {getVendorDialog({ vendorDetailsParams })}
                    {this.getDealsDialog({ event, dealsDialogParams })}
                  </div>
                </div>
              )
            })}
            <div className="paginationButtom">
              {this.state.paginationParams.totalPages > 0 && (
                getPagination(
                0,
                parseInt(this.state?.paginationParams?.currentPage),
                parseInt(this.state?.paginationParams?.totalPages),
                this.onPageChange,
                null,
                1
              ))}
            </div>
          </>
        );
      }
      else {
        return (
          <div className="noProdctsDisplay"><h1>No Vendors to Display!</h1></div>
        );
      }
    }
  }

  changeSortParam = (e) => {
    this.sortParam = e.target.value;
    this.page = "1";
    this.getReunionEventVendors();
  };

  changePageSize = (e) => {
    this.pageSize = e.currentTarget.value;
    this.page = "1";
    this.scrollPosition = window.pageYOffset;
    this.getReunionEventVendors();
  };

  changeSortDirection = (e) => {
    this.sortDirection = e.currentTarget.value;
    this.scrollPosition = window.pageYOffset;
    this.page = "1";
    this.getReunionEventVendors();
  };

  searchItems = (e) => {
    this.page = 1;
    this.getReunionEventVendors();
  };

  getDealsDialog = ({ event, dealsDialogParams }) => {
    return (
      <div className="dealDetails">
        <span className="noOfItems"
          data-id={event.vendorID}
          data-vendor-name={event.vendorName.trim()}
          data-memberterms={event.memberTerms}
          data-shipdate1={event.shipDate1}
          data-shipdate2={event.shipDate2}
          data-shipdate3={event.shipDate3}
          data-shipdate4={event.shipDate4}
          data-eventSupplierSelectionId={event.eventSupplierSelectionId}
          onClick={this.showItemList}
        >
          Item(s): {dealsDialogParams.num}
        </span>
        <span onClick={() => this.showDealDetails(dealsDialogParams.vendorName)} className='dealDeadline'>Deal Details</span>
        {dealsDialogParams.dealDetails && (
          dealsDialogParams.selectedvendorName === dealsDialogParams.vendorName &&
          <div id="dealDetailsDialog">
            <DealsDialog
              vendorName={dealsDialogParams.vendorName}
              member_terms={dealsDialogParams.member_terms}
              shipdate1={dealsDialogParams.shipDates[0]}
              shipdate2={dealsDialogParams.shipDates[1]}
              shipdate3={dealsDialogParams.shipDates[2]}
              shipdate4={dealsDialogParams.shipDates[3]}
              DialogClose={this.DialogClose}
            />
          </div>
        )}
      </div>
    );
  }

  getSortByOptions = () => {
    return [
      {
        props: { value: "num", id: "numOfItems", className: "SelectOption" },
        text: "Number of items",
      },
      {
        props: { value: "vendorname", id: "vendorName", className: "SelectOption" },
        text: "Vendor Name",
      }
    ];
  };

  getSearchByOptions = () => {
    return [{}];
  };

  getListingHeader = () => {
    const { paginationParams, flowType } = this.state;
    return (
      <div className="listing-header">
        <span>
          {paginationParams.firstItem}-{paginationParams.lastItem} of{" "}
          {paginationParams.totalItems} vendors
        </span>
        {paginationParams.totalPages > 0 && (
          <div className="headerPagination">
          <span className="pagination_top">
            {getPagination(
              0,
              parseInt(paginationParams.currentPage),
              parseInt(paginationParams.totalPages),
              this.onPageChange,
              null,
              1
            )}
          </span>
          <div className='paginationSearch'>
              <label htmlFor="search_PLPPagination">Enter Page No</label>
              <div className='inlineGrouptext'>
                <input
                  id='search_PLPPagination'
                  onChange={this.onSearchPageHandle}
                  value={this.state.searchPage}
                />
                <span className="rightLabel"> / {this.state.totalPages}</span>
              </div>
              <button className="red ui button paginationButton" onClick={this.onPageChange}>Go</button>
            </div>
        </div>
        )}
      </div>
    );
  };


  render() {
    const { 
      header, 
      eventVendorDetails,
      shoppingAreaId, 
      shoppingAreaMetaId,
      dealDetails, 
      selectedvendorName, 
      flowType,
      isFilterRequired, 
      filters, 
      isNoProducts, 
      fromReunionHome, 
      promoNmbr,
      relayNbr, 
      promoName 
    } = this.state;
    const shoppingAreaName = this.state.header;
    const gridClassName = window.innerWidth < 1024 
                            ? 'ui stackable two column grid' 
                            : window.innerWidth < 1240 
                              ? 'ui stackable three column grid' 
                              : 'ui stackable five column grid';
    return (
      <div className="pageWrap">
        {this.state.isLoading && (
          <div>
            <Loader active />
          </div>
        )}
        <div className='page-header'>
          {decodeURIComponent(header)} - {decodeURIComponent(promoName)}
        </div> 
        <BreadcrumbComp 
          path={
            getBreadcrumbLinks(
                fromReunionHome,
                flowType,
                null,
                null,
                header,
                shoppingAreaName,
                shoppingAreaId,
                shoppingAreaMetaId,
                promoNmbr,
                relayNbr,
                "VendorListingDropShipDiscountCity",
                `Events : ${decodeURIComponent(promoName)}`
              )} 
        /> 
        <OptionsSection
          isSortByRequired={isSortRequired(flowType)}
          sortByList={this.getSortByOptions()}
          changeSortParam={this.changeSortParam}
          isSearchRequired={false}
          searchByList={this.getSearchByOptions()}
          isShowByRequired={isShowByRequired(flowType)}
          searchItems={this.searchItems}
          pageSize={this.pageSize}
          sortOrder={this.sortDirection}
          changePageSize={this.changePageSize}
          isGridViewIconRequired={false}
          changeSortDirection={this.changeSortDirection}
          sortParam={this.sortParam}
        />
        <div className={window.innerWidth > 1024 ? 'displayFlex allOrdersTable' : 'allOrdersTable'}>
          {isFilterRequired && (
            <Filter
              filters={filters}
              isNoProducts={isNoProducts}
              appliedFilters={this.state.appliedFilters}
              clearFilters={false}
              filterCallback={this.filterCallback}
            />
          )}
          <div className = "full-width pl_data">
            {this.getListingHeader()}      
            <div className="pageDetails">
              <span className="paddingLeft">
                Event ID: #{this.state.relayNbr}
              </span>
              <span className="paddingLeft">
                Event Name: {decodeURIComponent(promoName)}
              </span>
              <span className="paddingLeft">
                Order Deadline: {this.state.orderDeadline}
              </span>
              <div className={gridClassName} >
                {this.getVendors(eventVendorDetails, dealDetails, selectedvendorName, flowType)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getprefer: state.preference.listData,
    marketDate: state.cart?.orders?.eventDate
  };
};

export default connect(mapStateToProps,null)(VendorListingDropShipDC);
