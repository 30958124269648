import React from "react";
import BreadcrumbComp from "../../../components/BreadcrumbComp";
import OptionsSection from "./OptionsSection";
import { getPagination } from "../../common/CustomComponents";
import ItemListView from "./ItemListView";
import ItemGridView from "./ItemGridView";
import Filter from "./Filter";
import restUtils from "../../../utils/restUtils";
import { REUNION_ITEM_LISTING } from "../../../urlConstants";
import { connect } from "react-redux";
import utilities,{shouldHideCostForPreference} from "../../../utils/utilities";
import { exportExcel, getFirstCartId } from "../../common/ComponentUtilities";
import { isFilterRequired } from "./ReunionComponentUtilities";
import { MaskPrice } from "../../common/MaskPrice";
import { viewAllCarts, updateQty } from "../../Cart/actions";
import {
  isSearchRequired,
  isSortRequired,
  getSortByOptions,
  getSearchByOptions,
  isShowByRequired,
  getBreadcrumbLinks,
  isAddToCartRequired,
  isSeqRequried,
  getPdpParams,
  getPageNumber,
  handleQtyChange,
  addToCart,
  getVendorDetails,
  exportLargeData,
  setScrollPosition,
} from "./itemListingUtilities";
import { Loader } from "semantic-ui-react";
import { isArray } from "util";
import _ from "lodash";
import UnderConstruction from "../../UnderConstruction";
import config from '../../../config';
import { getReuionItems } from '../../../views/common/GenericExcelExport/excelService';
import { MEMBER_VIEW } from '../../../components/NavBar/menuConstants';
import renderImages from '../../common/RenderImages';
import search_img from '../../images/Search.svg';
class ItemListing extends React.Component {
  constructor(props) {
    super(props);
    const {
      eventId, 
      flowType,
      fromReunionHome,
      relayNbr, 
      relayYear,
      shoppingId, 
      vendorId,
      vendorName 
    } = props.match?.params ? props.match?.params: props ;
    const shoppingAreaName = props?.match?.params?.shoppingAreaName ? decodeURIComponent(props?.match?.params?.shoppingAreaName): props?.shoppingAreaName;
    const promoName = decodeURIComponent(props?.match?.params?.promoName);
    const shoppingAreaMetaId = props?.location?.state?.shoppingAreaMetaId ? decodeURIComponent(props?.location?.state?.shoppingAreaMetaId): props?.shoppingMetaDataId;
    this.state = {
      fromReunionHome: fromReunionHome,
      flowType: flowType,
      relayNbr: relayNbr,
      promoName: promoName !== 'undefined' ? promoName : "",
      relayYear: relayYear,
      shoppingAreaName: shoppingAreaName,
      shoppingAreaId: shoppingId,
      shoppingAreaMetaId: shoppingAreaMetaId !== 'undefined' ? shoppingAreaMetaId : shoppingId,
      eventId: eventId,
      eventName: promoName,
      vendorId: vendorId ? vendorId : '',
      vendorName: vendorName ? vendorName : '',
      dealDetails: false,
      orderDeadline:'',
      isFilterRequired: isFilterRequired(flowType),
      filters: [],
      pageHeader: props.pageHeader,
      breadcrumbLinks: props.breadcrumbLinks,
      isLoading: false,
      productsData: [],
      quantities: [],
      overrideRetails: [],
      eventEnableAfter: '',
      eventEnableBefore:'',
      eventMessage: '',
      isNoProducts: false,
      imgTextToDisplay:
        props.getprefer &&
        props.getprefer.thumbnailImage &&
        props.getprefer.thumbnailImage !== "Y"
          ? "Show Images"
          : "Hide Images",
      isGridView:
        props.getprefer &&
        props.getprefer.prdctListlayoutFormat &&
        props.getprefer.prdctListlayoutFormat !== "L",
      paginationParams: {},
      productSelectedForModal: null,
      indexForModal: null,
      appliedFilters: {},
      searchPattern: "[0-9]*",
      searchValue: this.props?.searchValue ? this.props?.searchValue: "",
      searchPage:""
    };
    this.pageSize =
      this.props.getprefer && this.props.getprefer.resultPerPage
        ? this.props.getprefer.resultPerPage
        : "50";
    this.scrollPosition = 0;
    this.sortParam = this.state.shoppingAreaId === '5' ? 'seqno' : 'item';
    this.sortDirection = "asc";
    this.searchby = this.props?.searchBy ? this.props?.searchBy:"item";
    this.searchValue = this.props?.searchValue ? this.props?.searchValue: "";
    this.page = 1;
    this.defaultChecked = utilities.isEmptyOrNullString(vendorId)
      ? null
      : {
          name: "Vendor Name",
          value: vendorId,
        };
    this.filterList = this.props.fromScanning && this.props.filterBy ? this.props.filterBy : '';
    this.pathname = "";
    this.handleQtyChange = handleQtyChange.bind(this);
    this.addToCart = addToCart.bind(this);
    this.searchByOptions = getSearchByOptions(flowType,utilities.isAliasSkuRequired(this.props.multiStoreDetails));
    this.getVendorDetails = getVendorDetails.bind(this);
    this.setScrollPosition = setScrollPosition.bind(this);
    this.hideFilter = this.props.fromScanning  && true
  }

  componentDidMount() {
    this.props.updateQty(false)
    let queryParam = window.location.href.split("?");
    this.pathname = this.props.location && this.props.location.pathname;
    if (queryParam[1]) {
      this.getUrlFilters(queryParam);
    } else {
      this.fetchItems();
    }
  }

  closeMultistoreModal = ()=>{
    //this.fetchItems();
  }
  componentDidUpdate() {
    if(this.props.qtyUpdate) {
      this.scrollPosition = window.pageYOffset;
      if(!this.props?.fromScanning) {
        this.fetchItems();
      }      
      this.props.updateQty(false)
    }
  }
  
  handleViewChange = (isGridView) => {
    this.setState({ isGridView });
  };
  
  showHideImages = (evt) => {
    let imgTextToDisplay =
      evt.target.innerHTML === "Show Images" ? "Hide Images" : "Show Images";
    this.setState({ imgTextToDisplay });
  };

  showDealDetails() {
    this.setState({
      dealDetails: true,
    });
  }
  DialogClose = () => this.setState({ dealDetails: false });
  getRequestPayload = (isCompleteList) => {
    const {
      eventId,
      relayNbr,
      relayYear,
      vendorId,
      paginationParams,
      searchValue,
      shoppingAreaId,
      shoppingAreaMetaId,
      flowType
    } = this.state;
    const resultPerPage = !isCompleteList
      ? this.pageSize
      : this.state?.paginationParams?.totalItems;
      let currentCart = this.props?.carts 
                      && this.props?.carts.find(cart => parseInt(cart.cartId) === parseInt(this.props.cartId));
    let getCartId = getFirstCartId(shoppingAreaId,eventId,this.props.carts, this.props.currentCartId)
    return {
      memNbr: this.props.storeId,
      ...(!utilities.isEmptyOrNullString(searchValue)
        ? {
            searchby: this.searchby,
            searchvalue: searchValue,
          }
        : {}),
      promoNbr: eventId,
      relayNbr: relayNbr,
      relayYear: relayYear,
      flowType,
      VendorID: !vendorId ? null : vendorId,
      sortBy: this.sortParam,
      sortOrder: this.sortDirection,
      page: isCompleteList ? "1" : this.page,
      cartId: getCartId,
      multiStoreCartId : utilities.msExist(this.props.multiStoreDetails) > 1 && currentCart?.parentId ? currentCart.parentId : '',
      resultPerPage,
      rdcNbr: this.props.rdc,
      isItemDtlFromWH: false,
      shoppingId:shoppingAreaId,
      shoppingMetaDataId:shoppingAreaMetaId ? shoppingAreaMetaId : shoppingAreaId,
      aliasSKURetailerGrpID: utilities.isAliasSkuRequired(
        this.props.multiStoreDetails
      )
        ? 1
        : 0,
      ...(!_.isEmpty(this.filterList) ? { FilterBy: this.filterList } : {}),
    };
  };
  fetchItems = () => {
    this.setState({ isLoading: true });
    const payload = this.getRequestPayload();
    restUtils.postData(REUNION_ITEM_LISTING, payload).then((response) => {
      if(response.data && (response.data?.evtStsActiveBefore === false || response.data?.evtStsActiveAfter === false)) {
        this.setState({
          eventEnableAfter: response.data?.evtStsActiveAfter,
          eventEnableBefore: response.data?.evtStsActiveBefore,
          eventMessage: response.data?.Message
        })
        return;
      } else if (
        !response?.data?.reunionItemList ||
        response.data.reunionItemList.length === 0
      ) {
        this.setState((prevState) => {
          return {
            isLoading: false,
            productsData: [],
            isNoProducts: true,
            paginationParams: {
              ...prevState.paginationParams,
              totalPages: "0",
              currentPage: "0",
              totalItems: "0",
              firstItem: "0",
              lastItem: "0",
            },
          };
        });
        return;
      }
      const { data } = response;
      let filters = response?.data?.filters;
      filters.forEach(filter=>{
        if(filter.name==="Vendor Name"){
          filter.valueList = utilities.objSortValues(filter.valueList);
        }
      });
      this.setState({
        isLoading: false,
        isNoProducts: false,
        productsData: data.reunionItemList,
        paginationParams: data.pagination[0],
        promoName:response.data?.PromoName?response.data?.PromoName:this.state.promoName,
        orderDeadline: response.data?.OrderDeadline ? utilities.getDateFromTimeStamp(response.data.OrderDeadline): '',
        filters,
        quantities: data.reunionItemList?.map((product) => {
          return [
            utilities.emptyForNull(product.userQty),
            utilities.emptyForNull(product.userQty2),
            utilities.emptyForNull(product.userQty3),
            utilities.emptyForNull(product.userQty4),
          ];
        }),
        overrideRetails: data.reunionItemList.map((product) =>
          utilities.emptyForNull(product.overrideExisting)
        ),
      },()=>{
        window.scrollTo(0, this.scrollPosition);
      });
    }
    ).catch((error) => {
      this.setState({ isLoading: false });
      console.log(error);
    });
  };
  filterCallback = (isAdd, filterKey, filterValue) => {
    let { appliedFilters } = this.state;
    this.scrollPosition = window.pageYOffset;
    let keyExists = appliedFilters.hasOwnProperty(filterKey);
    if (!keyExists && isAdd) {
      appliedFilters = { ...appliedFilters, [filterKey]: [filterValue] };
    } else if (keyExists) {
      let value = appliedFilters[filterKey];
      let index = value.indexOf(filterValue);
      if (isArray(value) && index > -1) {
        value.splice(index, 1); // remove if value exists
      } else {
        value.push(filterValue); // add if value is new
      }
      if (!value.length && keyExists) {
        delete appliedFilters[filterKey];
      } else {
        appliedFilters[filterKey] = value;
      }
    }
    this.filterList = utilities.getFilterListFromObject(appliedFilters);
    this.setState(
      {
        currentPage: 1,
        appliedFilters,
        clearFilters: false,
        backPath: this.pathname,
      },
      () => {
        this.page = 1;
        this.fetchItems();
        this.setBackButtonPath(appliedFilters);
      }
    );
  };
  changePageSize = (e) => {
    this.pageSize = e.currentTarget.value;
    this.page = "1";
    this.scrollPosition = window.pageYOffset;
    this.fetchItems();
    this.setBackButtonPath();
  };

  onPageChange = (e) => {
    const { paginationParams } = this.state;
    if(this.state.searchPage !== '' && Number(this.state.searchPage) > Number(paginationParams.totalPages)){
      utilities.showToast(`Please enter the page is less than ${paginationParams.totalPages}. The page is not available.`, true,'centerToast',9000);
      return;
    }
    let queryPage = e.currentTarget?.text || this.state.searchPage;
    this.page = getPageNumber(queryPage, paginationParams);
    this.scrollPosition = 0;
    window.scrollTo(0, 0);
    this.fetchItems();
    this.setBackButtonPath();
  };
  onSearchPageHandle = (e) => {
    this.setState({
       searchPage:e.target.value
    })
   };

  getListingHeader = () => {
    const { paginationParams, imgTextToDisplay } = this.state;
    return (
      <div className="listing-header">
        <span>
          {paginationParams.firstItem}-{paginationParams.lastItem} of{" "}
          {paginationParams.totalItems} products
        </span>
        <span onClick={this.showHideImages} id="show_and_hide_images">
          {imgTextToDisplay}
        </span>
        {paginationParams.totalPages > 0 && (
          <div className="headerPagination">
          <span className="pagination_top">
            {getPagination(
              0,
              parseInt(paginationParams.currentPage),
              parseInt(paginationParams.totalPages),
              this.onPageChange,
              null,
              1
            )}
          </span>
          <div className='paginationSearch'>
              <label htmlFor="search_PLPPagination">Enter Page No</label>
              <div className='inlineGrouptext'>
                <input
                  id='search_PLPPagination'
                  onChange={this.onSearchPageHandle}
                  value={this.state.searchPage}
                />
                <span className="rightLabel"> / {this.state.totalPages}</span>
              </div>
              <button className="red ui button paginationButton" onClick={this.onPageChange}>Go</button>
            </div>
        </div>
        )}
        {!this.props?.fromScanning &&
         exportExcel({
          className: "exportPDF exportPdfPLP",
          onClick: this.handleExport,
        })}
      </div>
    );
  };
  getFieldSet = (product, index) => {
    let quantity = this.state.quantities[index];
    let overrideRetail = this.state.overrideRetails[index];
    const { access, CatMbr } = this.props?.multiStoreDetails;
    const { getprefer, memberViewType } = this.props;
    const { flowType, shoppingAreaId,shoppingAreaMetaId } = this.state;
    const memberCost = (
      <MaskPrice
        viewType={memberViewType}
        getprefer={utilities.getPreferenceHideMask(getprefer)}
        fieldVal={utilities.getCostForQtyHotDeals(
          product?.pricing,
          quantity,
          true
        )}
        field={"Member Cost black"}
      />
    );
    const costWithTier2 = utilities.getCostWithTier2(
      product,
      memberCost,
      quantity,
      memberViewType
    );
    const futureCostRunion = (
      <MaskPrice
        viewType={memberViewType}
        getprefer={utilities.getPreferenceHideMask(getprefer)}
        fieldVal={product?.futureCost}  
        field={"Member Cost black"}
      />
    );
  const futurecostWithTier2 = utilities.getCostWithTier2(
    product,
    futureCostRunion,
    quantity,
    memberViewType
  );
    const evtCost = utilities.getEventCost(
      product,
      utilities.getPreferenceHideMask(getprefer),
      memberViewType
    );
    const evtCostAtReunion = parseFloat(product.spcCost) === 0.0 || product.savings ===100;
    const savings =evtCostAtReunion || utilities.isEmptyOrNullString(product.savings)
                    ? "N/A"
                    : utilities.hyphenForZeroPercentage(product.savings);
    const maxQtyExists = product?.maxQtyItemRestriction>0;
    const maxQtyPerReunionExists = product?.maxQtyPerReunion>0;
    const displayMemberCostAndLbl =  shouldHideCostForPreference(memberViewType,getprefer.customerView);
    return !this.state.isGridView && window.innerWidth >= 1024
      ? [
          ...(access !== 1 || (access === 1 && CatMbr)
            ? [
                [
                  { label: "Item#:", val: product.itemNbr },
                  ...(access === 1 && access !== 1 && !CatMbr
                    ? []
                    : [
                      displayMemberCostAndLbl && {
                          label: `${utilities.getLabelsByType(memberViewType,"REGULAR_COST")}:`,
                          val: product.futureCost && product.futureCost > 0 ? utilities.emptyPriceCheck(futurecostWithTier2) : utilities.emptyPriceCheck(costWithTier2),
                        },
                      ]),
                  { label: "Ship Week:", val: "QUANTITY" },
                ],
                [
                  { label: "Model#:", val: product.model },
                  ...(access === 1 && access !== 1 && !CatMbr
                    ? []
                    : [
                      displayMemberCostAndLbl && {
                          label: `${utilities.getLabelsByType(memberViewType,"EVENT_COST")}:`,
                          val: evtCost,
                          labelClass: "red_color First_level_details",
                        },
                      ]),
                  ...(utilities.isEmptyOrNullString(product.shipDate1)
                    ? []
                    : [
                        {
                          label: utilities.getDateFromTimeStamp(
                            product.shipDate1
                          ),
                          type: "qty1",
                          index,
                          isTextBox: true,
                          val: quantity[0],
                        },
                      ]),
                ],
                [
                  { label: "UPC:", val: utilities.emptyForNull(product.upcNum) },
                  memberViewType === MEMBER_VIEW && {
                    label: "Savings:",
                    val: savings,
                    labelClass: "red_color First_level_details",
                  },
                  ...(utilities.isEmptyOrNullString(product.shipDate2)
                    ? []
                    : [
                        {
                          label: utilities.getDateFromTimeStamp(
                            product.shipDate2
                          ),
                          type: "qty2",
                          index,
                          isTextBox: true,
                          val: quantity[1],
                        },
                      ]),
                ],
                [
                  
                  {
                    label: "Pack:",
                    val: `${product.sPackType ? product.sPackType : ""}${
                      product.sPackQty ? product.sPackQty : ""
                    }`,
                  },
                  {
                    label: "Override Retail:",
                    val: overrideRetail,
                    index,
                    type: "or",
                    isTextBox: true,
                  },
                  ...(utilities.isEmptyOrNullString(product.shipDate3)
                    ? []
                    : [
                        {
                          label: utilities.getDateFromTimeStamp(
                            product.shipDate3
                          ),
                          type: "qty3",
                          index,
                          isTextBox: true,
                          val: quantity[2],
                        },
                      ]),
                ],
                [
                  {
                    label: "Non-Stocked:",
                    val: product.stocked,
                  },                 
                    ...(shoppingAreaMetaId === "7"
                    ? [
                        {
                          label: "AVAILABLE:",
                          val: product.available,
                          labelClass: product.available > 0 ? 'availableWH' : 'red_color First_level_details'
                        },
                      ]
                    : []),
                  ...(utilities.isAliasSkuRequired(this.props.multiStoreDetails)
                    ? [
                        {
                          label: "Alternate SKU# :",
                          val: utilities.hyphenForNull(product.aliasSku),
                        },
                      ]
                    : [{}]),
                  ...(utilities.isEmptyOrNullString(product.shipDate4)
                    ? []
                    : [
                        {
                          label: utilities.getDateFromTimeStamp(
                            product.shipDate4
                          ),
                          type: "qty4",
                          index,
                          isTextBox: true,
                          val: quantity[3],
                        },
                      ]),
                ],
                [
                  ...maxQtyExists?[
                    {label : product.isMultiples?"Pallet Qty :":"Max Qty:",
                       val:product.maxQtyItemRestriction,	            
                    }
                  ]:[],
                    ...(product?.Restrictions?.length > 0 ?[{
                      label : 'RESTRICTED:',
                      val : (product?.Restrictions?.length > 0)
                        ? utilities.restrictedDetail(product?.Restrictions)
                          : '',
                        className: 'First_level_details t-center restricted-product'
                    }] : []),                  
                  ...maxQtyPerReunionExists?[
                  {label:"Max Qty Per Ship Window:",
                    val:product.maxQtyPerReunion,
                    labelClass: "max_red_color"
                  }
                ]:[],]
              ]
            : [
                [
                  { label: "Item#:", val: product.itemNbr },
                  { label: "Model#:", val: product.model },
                  { label: "Ship Week:", val: "QUANTITY" },
                ],
                [
                  { label: "UPC:", val: utilities.emptyForNull(product.upcNum) },
                  {
                    label: "Override Retail:",
                    val: overrideRetail,
                    index,
                    type: "or",
                    isTextBox: true,
                  },
                  ...(utilities.isEmptyOrNullString(product.shipDate1)
                    ? []
                    : [
                        {
                          label: utilities.getDateFromTimeStamp(
                            product.shipDate1
                          ),
                          type: "qty1",
                          index,
                          isTextBox: true,
                          val: quantity[0],
                        },
                      ]),
                ],
                [
                  {
                    label: "Pack:",
                    val: `${product.sPackType ? product.sPackType : ""}${
                      product.sPackQty ? product.sPackQty : ""
                    }`,
                  },
                  {
                    label: "Savings:",
                    val: savings,
                    labelClass: "red_color First_level_details",
                  },
                  ...(utilities.isEmptyOrNullString(product.shipDate2)
                    ? []
                    : [
                        {
                          label: utilities.getDateFromTimeStamp(
                            product.shipDate2
                          ),
                          type: "qty2",
                          index,
                          isTextBox: true,
                          val: quantity[1],
                        },
                      ]),
                ],
                [
                  {
                    label: "Non-Stocked:",
                    val: product.stocked,
                  },
                  ...(utilities.isEmptyOrNullString(product.shipDate3)
                    ? []
                    : [
                        {
                          label: utilities.getDateFromTimeStamp(
                            product.shipDate3
                          ),
                          type: "qty3",
                          index,
                          isTextBox: true,
                          val: quantity[2],
                        },
                      ]),
                ],
                [
                  ...maxQtyExists?[
                    {label : "Max Qty :",
                      val:product.maxQtyItemRestriction
                    }
                  ]:[],

                  ...(utilities.isAliasSkuRequired(this.props.multiStoreDetails)
                    ? [
                        {
                          label: "Alternate SKU# :",
                          val: utilities.hyphenForNull(product.aliasSku),
                        },
                      ]
                    : []),

                  ...(utilities.isEmptyOrNullString(product.shipDate4)
                    ? []
                    : [
                        {
                          label: utilities.getDateFromTimeStamp(
                            product.shipDate4
                          ),
                          type: "qty4",
                          index,
                          isTextBox: true,
                          val: quantity[3],
                        },
                      ]),
                ],
              ]),
        ]
      : [
          ...(access !== 1 || (access === 1 && CatMbr)
            ? [
                { label: "Item#:", val: product.itemNbr },
                { label: "Model#:", val: product.model },
                { label: "UPC:", val: utilities.emptyForNull(product.upcNum) },
                {
                  label: "Pack:",
                  val: `${product.sPackType ? product.sPackType : ""}${
                    product.sPackQty ? product.sPackQty : ""
                  }`,
                },
                {
                  label: "Non-Stocked:",
                  val: product.stocked,
                },
                ...(
                  displayMemberCostAndLbl ? [{
                    label: `${utilities.getLabelsByType(memberViewType,"REGULAR_COST")}:`,
                    val: product.futureCost && product.futureCost > 0 ? utilities.emptyPriceCheck(futurecostWithTier2) : utilities.emptyPriceCheck(costWithTier2),
                  },
                ] : []
                ),
                ...( displayMemberCostAndLbl ? [{
                  label: `${utilities.getLabelsByType(memberViewType,"EVENT_COST")}:`,
                  val: evtCost,
                  classname: "red_color First_level_details",
                },] : []
                ),
                ...(shoppingAreaMetaId === "7"
                  ? [
                      {
                        label: "AVAILABLE:",
                        val: product.available,
                        classname: product.available > 0 ? 'availableWH' : 'red_color First_level_details'
                      },
                    ]
                  : []),
                  ...( memberViewType === MEMBER_VIEW ? [{
                    label: "Savings:",
                    val: savings,
                    classname: "red_color First_level_details",
                  },
                ] : []),                  
                {
                  label: "Override Retail:",
                  val: overrideRetail,
                  index,
                  type: "or",
                  index,
                  isTextBox: true,
                },
                ...(utilities.isAliasSkuRequired(this.props.multiStoreDetails)
                  ? [
                      {
                        label: "Alternate SKU# :",
                        val: utilities.hyphenForNull(product.aliasSku),
                      },
                    ]
                  : []),
                  ... maxQtyExists? [{label:product.isMultiples?"Pallet Qty" :"Max Qty:", val: product.maxQtyItemRestriction}]:[],
                  ...maxQtyPerReunionExists?[
                    { label:"Max Qty Per Ship Window:",
                      val:product.maxQtyPerReunion,
                      labelClass: "max_red_color"
                    }
                    ]:[],
                  { label: "Ship Week:", val: "QUANTITY" },
                ...(utilities.isEmptyOrNullString(product.shipDate1)
                  ? []
                  : [
                      {
                        label: utilities.getDateFromTimeStamp(
                          product.shipDate1
                        ),
                        type: "qty1",
                        index,
                        val: quantity[0],
                        isTextBox: true,
                      },
                    ]),
                ...(utilities.isEmptyOrNullString(product.shipDate2)
                  ? []
                  : [
                      {
                        label: utilities.getDateFromTimeStamp(
                          product.shipDate2
                        ),
                        type: "qty2",
                        index,
                        val: quantity[1],
                        isTextBox: true,
                      },
                    ]),
                ...(utilities.isEmptyOrNullString(product.shipDate3)
                  ? []
                  : [
                      {
                        label: utilities.getDateFromTimeStamp(
                          product.shipDate3
                        ),
                        type: "qty3",
                        index,
                        val: quantity[2],
                        isTextBox: true,
                      },
                    ]),
                ...(utilities.isEmptyOrNullString(product.shipDate4)
                  ? []
                  : [
                      {
                        label: utilities.getDateFromTimeStamp(
                          product.shipDate4
                        ),
                        type: "qty4",
                        index,
                        val: quantity[3],
                        isTextBox: true,
                      },
                    ]),
                    ...(product?.Restrictions?.length > 0 ?[{
                      label : 'RESTRICTED:',
                      val : (product?.Restrictions?.length > 0)
                        ? utilities.restrictedDetail(product?.Restrictions)
                        : '',
                        className: 't-center restricted-product'
                      }] : []),
              ]
            : [
                { label: "Item#:", val: product.itemNbr },
                { label: "Model#:", val: product.model },
                { label: "UPC :", val: product.upcNum },
                {
                  label: "Savings:",
                  val: savings,
                  labelClass: "red_color First_level_details",
                },
                {
                  label: "Pack:",
                  val: `${utilities.emptyForNull(
                    product.sPackType
                  )}${utilities.emptyForNull(product.sPackQty)}`,
                },
                {
                  label: "Non-Stocked:",
                  val: product.stocked,
                },
                ...(utilities.isAliasSkuRequired(this.props.multiStoreDetails)
                  ? [
                      {
                        label: "Alternate SKU# :",
                        val: utilities.hyphenForNull(product.aliasSku),
                      },
                    ]
                  : []),
                { label: "Ship Week:", val: "QUANTITY" },
                ...(utilities.isEmptyOrNullString(product.shipDate1)
                  ? []
                  : [
                      {
                        label: utilities.getDateFromTimeStamp(
                          product.shipDate1
                        ),
                        type: "qty1",
                        index,
                        val: quantity[0],
                        isTextBox: true,
                      },
                    ]),
                ...(utilities.isEmptyOrNullString(product.shipDate2)
                  ? []
                  : [
                      {
                        label: utilities.getDateFromTimeStamp(
                          product.shipDate2
                        ),
                        type: "qty2",
                        index,
                        val: quantity[1],
                        isTextBox: true,
                      },
                    ]),
                ...(utilities.isEmptyOrNullString(product.shipDate3)
                  ? []
                  : [
                      {
                        label: utilities.getDateFromTimeStamp(
                          product.shipDate3
                        ),
                        type: "qty3",
                        index,
                        val: quantity[2],
                        isTextBox: true,
                      },
                    ]),
                ...(utilities.isEmptyOrNullString(product.shipDate4)
                  ? []
                  : [
                      {
                        label: utilities.getDateFromTimeStamp(
                          product.shipDate4
                        ),
                        type: "qty4",
                        val: quantity[3],
                        index,
                        isTextBox: true,
                      },
                    ]),
                    ...(product?.Restrictions?.length > 0 ?[{
                      label : 'RESTRICTED:',
                      val : (product?.Restrictions?.length > 0)
                        ? utilities.restrictedDetail(product?.Restrictions)
                        : '',
                        className: 't-center restricted-product'
                      }] : []),
              ]),
        ];
  };
  getItemParams = (product, index) => {
    let itemParams = { product };
    const fieldSet = this.getFieldSet(product, index);
    itemParams = {
      ...itemParams,
      fieldSet,
      pdpParams: getPdpParams(this.state.flowType, product),
      itemIndex: index,
    };
    return itemParams;
  };
  getItemListParams = () => {
    const { flowType } = this.state;
    return {
      handleQtyChange: this.handleQtyChange,
      isAddToCartRequired: isAddToCartRequired(flowType),
      isScanListing: false,
      isSeqRequired: isSeqRequried(flowType),
    };
  };

  getUrlFilters = (queryParam) => {
    // to reinitialise fields from url
    const queryString = queryParam[1];
    let getParams = queryParam[1].split("&");
    this.page = getParams[0].split("=")[1];
    this.pageSize = getParams[1].split("=")[1];
    this.sortParam = queryString.includes("&SortParam=")
      ? this.getFieldFromUrl(queryString, "SortParam=")
      : this.sortParam;
    this.sortDirection = queryString.includes("SortType=asc")
      ? "asc"
      : queryString.includes("SortType=desc")
      ? "desc"
      : this.sortDirection;
    if (queryString.includes("&SearchBy=")) {
      this.searchby = this.getFieldFromUrl(queryString, "SearchBy=");
    }
    if (queryString.includes("&SearchValue=")) {
      this.searchValue = this.getFieldFromUrl(queryString, "SearchValue=");
    }
    this.scrollPosition = queryString.includes("scrollPosition=")
      ? this.getFieldFromUrl(queryString, "scrollPosition=")
      : 0;
    let filterString = queryString.includes("filterString")
      ? queryString.split("filterString=")[1]
      : "";
    let appliedFilters = utilities.getFilterArrayAsMultiSelectObject(
      filterString
    );
    this.filterList = utilities.getFilterListFromObject(appliedFilters);
    this.setState(
      {
        searchValue: this.searchValue,
        appliedFilters: appliedFilters,
      },
      () => {
        this.fetchItems();
      }
    );
  };
  getFieldFromUrl = (queryString, fieldName) => {
    return queryString?.split(fieldName)[1]?.split("&")[0];
  };
  setBackButtonPath = (newAppliedFilters) => {
    const appliedFilters = newAppliedFilters
      ? newAppliedFilters
      : this.state.appliedFilters;
    let pathName = "";
    pathName = utilities.isEmptyOrNullString(this.page)
      ? "Page=1"
      : "Page=" + this.page;
    pathName = pathName + "&ResultsPerPage=" + this.pageSize;
    if (this.sortParam && this.sortParam.length > 0) {
      pathName = pathName + "&SortParam=" + this.sortParam;
      if (this.sortDirection && this.sortDirection.length > 0) {
        pathName = pathName + "&SortType=" + this.sortDirection;
      } else {
        pathName = pathName + "&SortType=asc";
      }
    }
    
    if (!utilities.isEmptyOrNullString(this.state.searchValue)) {
      pathName =
        pathName +
        "&SearchBy=" +
        this.searchby +
        "&SearchValue=" +
        this.state.searchValue;
    }
    pathName =
      this.scrollPosition > 0
        ? pathName + "&scrollPosition=" + this.scrollPosition
        : pathName;
    if (!_.isEmpty(this.filterList)) {
      pathName =
        pathName +
        "&filterString=" +
        utilities.getQueryStrForFilterString(appliedFilters);
    }
    if (pathName !== "") {
      let pushUrl = this.pathname + "?" + pathName;
      if(!this.props.fromScanning) {
        window.history.replaceState(window.history.state, "", pushUrl);
      }
    }
  };
  getListing = () => {
    const cartParams = {
      addToCart: this.addToCart,
    };
    const imgStyle =
      this.state.imgTextToDisplay === "Hide Images"
        ? "displayBlock"
        : "displayNone";
    const { isGridView } = this.state;
    const itemListParams = this.getItemListParams();
    return (
      <div
        className={
          isGridView ? window.innerWidth >= 1220 ? "ui stackable four column grid" : "ui stackable three column grid": ""
        }
      >
        {this.state.productsData.map((product, index) => {
          const itemParams = this.getItemParams(product, index);
          return (
            <>
              {!isGridView && window.innerWidth >= 1024 ? (
                <ItemListView
                  indexCheck ={index}
                  quantities={this.state.quantities}
                  quantitiesMs={this.state.quantities}
                  flowType={this.state.flowType}
                  cartParams={cartParams}
                  itemParams={itemParams}
                  imgStyle={imgStyle}
                  product={itemParams.product}
                  shoppingAreaName={this.state.shoppingAreaName}
                  shoppingAreaId={this.state.shoppingAreaId}
                  shoppingAreaMetaId={this.state.shoppingAreaMetaId}
                  itemListParams={itemListParams}
                  setBackButtonPath={this.setScrollPosition}
                  closeMultistoreModal={this.closeMultistoreModal}

                />
              ) : (
                <ItemGridView
                  indexCheck ={index}
                  quantities={this.state.quantities}
                  quantitiesMs={this.state.quantities}
                  flowType={this.state.flowType}
                  cartParams={cartParams}
                  itemParams={itemParams}
                  shoppingAreaName={this.state.shoppingAreaName}
                  shoppingAreaId={this.state.shoppingAreaId}
                  shoppingAreaMetaId={this.state.shoppingAreaMetaId}
                  imgStyle={imgStyle}
                  product={itemParams.product}
                  itemListParams={itemListParams}
                  setBackButtonPath={this.setScrollPosition}
                  closeMultistoreModal={this.closeMultistoreModal}
                />
              )}
            </>
          );
        })}
      </div>
    );
  };
  searchItems = (e) => {
    this.page = 1;
    this.fetchItems();
    this.setBackButtonPath();
  };
  changeSearchValue = (e) => {
    this.setState({ searchValue: e.target.value });
  };
  changeSearchBy = (e) => {
    const option = this.searchByOptions.find(
      (option) => option?.props?.value === e.target.value
    );
    const pattern = option?.pattern;
    this.searchby = e.target.value;
    this.setState({ searchPattern: pattern, searchValue: "" });
  };
  changeSortParam = (e) => {
    this.sortParam = e.target.value;
    this.page = 1;
    this.fetchItems();
    this.setBackButtonPath();
  };
  changeSortDirection = (e) => {
    this.sortDirection = e.target.value;
    this.sortParam = utilities.isEmptyOrNullString(this.sortParam)
      ? "vendorname"
      : this.sortParam;
    this.page = 1;
    this.fetchItems();
    this.setScrollPosition();
    this.setBackButtonPath();
  };

  handleExport = () => {
    let payload = this.getRequestPayload();
    utilities.showToast("Exporting to Excel...");
    if(this.state.flowType === '1'){
      payload['sortBy'] = 'item'
    }
    let allOrdersToDownload = [];
    let allOrdersData = {};
    getReuionItems(payload, this.state?.paginationParams?.totalItems ).then(response=>{
      response.map((details)=>{
        details && details.reunionItemList && details.reunionItemList.map((product,index)=>{
          let quantity = this.state.quantities[index];
          let tierValue = utilities.getTierLevelForQtyHotDeals((product || (product?.pricing)), quantity)
          if ( product.length === 0 ) {
            utilities.showToast("No items available.", true);
            return;
          }
          const preference = utilities.getPreferenceHideMask(
            this.props.getprefer
          );
          const savings = utilities.isEmptyOrNullString(product.spcCost) || parseFloat(product.spcCost) === 0.0
                            ? ""
                            : utilities.emptyForZeroPercentage(product.savings);
          allOrdersData = { ...allOrdersData, ...{ 
            "Item #": utilities.emptyForNull(product.itemNbr),
            'Short Description': utilities.emptyForNull(product.shortDesc),
            "New Item Indicator": utilities.emptyForNull(product.newItemFlag),
            "Brand Indicator": utilities.emptyForNull(product.brandName)
          } };

          if (utilities.isAliasSkuRequired(this.props.multiStoreDetails)) {
            allOrdersData = {
              ...allOrdersData,
              ...{ "Alternate SKU#": utilities.emptyForNull(product.aliasSku) },
            };
          }
          allOrdersData = {
            ...allOrdersData,
            ...{
              "Vendor Name": utilities.emptyForNull(product.vendorName),
              "Sequence #": utilities.emptyForNull(product.seqNbr),
              'Model #': utilities.emptyForNull(product.model),
              Pack: `${product.sPackType ? product.sPackType : ""}${
                product.sPackQty ? product.sPackQty : ""
              }`,
              'UPC #': utilities.emptyForNull(product.upcNum),
              "Item Description": config.showMediumDesp ?
              utilities.emptyForNull(
                product.productTitle || product.longDescription
              ) : utilities.emptyForNull(
                product.longDescription
              ),
              Pack: `${product.sPackType ? product.sPackType : ""}${
                product.sPackQty ? product.sPackQty : ""
              }`,
            }
          }
          if(this.props.memberViewType !== MEMBER_VIEW){
            if(preference === 'c3' ||  preference === 'c4'){
              allOrdersData = {...allOrdersData}             
            }
            else {
              allOrdersData = { ...allOrdersData,...{
                    "RC": utilities.emptyForNull(
                      utilities.getCostBasedOnnatvalue(
                        this.props.memberViewType,
                        product.cost,
                        preference
                      )
                    ),                
                    "EVC": utilities.emptyForNull(
                      utilities.getCostBasedOnnatvalue(
                        this.props.memberViewType,
                        product.spcCost,
                        preference
                      )
                    )
                  }
                }  
              } 
            } else { 
          allOrdersData = { ...allOrdersData,...{
              "Regular Cost": utilities.emptyForNull(
                utilities.getCostBasedOnnatvalue(
                  this.props.memberViewType,
                  product.cost,
                  preference
                )
              ),
              'Regular Cost Tier Indicator': tierValue>0 ? "T"+tierValue : "" ,
              "Event Cost": utilities.emptyForNull(
                utilities.getCostBasedOnnatvalue(
                  this.props.memberViewType,
                  product.spcCost,
                  preference
                )
              )
                }
            }
          }
          if(this.props.memberViewType === MEMBER_VIEW){
            allOrdersData = { ...allOrdersData,...{
              "Savings": savings,
            }}
          }  
              allOrdersData = { ...allOrdersData,...{
              ...(this.state.shoppingAreaMetaId === "7"  ?
                {
                  "AVAILABLE": utilities.zeroForNull(product.available),
                }
                : {}),
              "Ship Week 1": product.shipDate1 && product.shipDate1 !== 'Invalid date' ? utilities.getDateFromTimeStamp(
                product.shipDate1
              ) : '',
              'Qty Ship Week 1': utilities.emptyForNull(product.userQty),
              "Ship Week 2": product.shipDate2 && product.shipDate2 !== 'Invalid date' ? utilities.getDateFromTimeStamp(
                product.shipDate2
              ) : '',
              'Qty Ship Week 2': utilities.emptyForNull(product.userQty2),
              "Ship Week 3": product.shipDate3 && product.shipDate3 !== 'Invalid date' ? utilities.getDateFromTimeStamp(
                product.shipDate3
              ) : '',
              'Qty Ship Week 3': utilities.emptyForNull(product.userQty3),
              "Ship Week 4": product.shipDate4 && product.shipDate4 !== 'Invalid date' ? utilities.getDateFromTimeStamp(
                product.shipDate4
              ) : '',
              'Qty Ship Week 4': utilities.emptyForNull(product.userQty4),
              "Non Stocked": product.stocked && product.stocked !== 'N/A' ? product.stocked : '',
              'Restricted Product': utilities.itemRestricted(product?.Restrictions),
              'Restriction Message': utilities.itemRestrictionDetails(product?.Restrictions).replace('{@ShowSubscribeLink}','')
            },
          };
          allOrdersToDownload.push(allOrdersData);
          return product;
        })
      })
      utilities.exportToCsv(allOrdersToDownload, this.state.shoppingAreaName);
    }).catch(error=>{
      utilities.showToast("Unexpected error occured please try after some time");
      console.log(error);
    })
  };

  render() {
    const {
      isLoading,
      isNoProducts,
      shoppingAreaId,
      shoppingAreaMetaId,
      shoppingAreaName,
      promoName,
      eventId,
      eventName,
      isFilterRequired,
      flowType,
      vendorId,
      vendorName,
      filters,
      fromReunionHome,
      relayNbr,
      relayYear,
      searchPattern,
      searchValue,
      eventEnableAfter,
      eventEnableBefore, 
      eventMessage,
      productsData
    } = this.state;
    if((eventEnableAfter === false || eventEnableBefore === false ) || productsData ) {
      return (
        <>
        {(eventEnableAfter === false || eventEnableBefore === false ) ? 
          <UnderConstruction  eventMessage={eventMessage} shoppingAreaName={shoppingAreaName}/> :
        <div className="page-wrap">
          {!this.props?.fromScanning ? (
            <>
          <div className="page-header">
            {shoppingAreaName} {promoName && `- ${eventId} - ${promoName}`}
          </div>
          <BreadcrumbComp
            path={getBreadcrumbLinks(
              fromReunionHome,
              flowType,
              eventId,
              vendorId,
              vendorName,
              shoppingAreaName,
              shoppingAreaId,
              shoppingAreaMetaId,
              relayYear,
              relayNbr,
              promoName,
              eventName,
              '',
              '',
              '',
              vendorId?'ItemListing':'AllItems',
            )}
          />          
          </>
          ):<h2>{shoppingAreaName}</h2>}
          <br />                   
          {vendorId ? this.getVendorDetails(vendorName, this.state.orderDeadline , vendorId) : promoName !== "" ? <div className="monthlyFields">{`${eventId} - ${promoName}`} &nbsp;&nbsp;Order Deadline: {this.state.orderDeadline}</div> : ""}
          {!this.hideFilter &&(
          <OptionsSection
            isSortByRequired={isSortRequired(flowType)}
            sortByList={getSortByOptions(
              flowType,
              utilities.isAliasSkuRequired(this.props.multiStoreDetails),
              null,
	            shoppingAreaId
            )}
            changeSortParam={this.changeSortParam}
            isSearchRequired={isSearchRequired(flowType)}
            searchByList={this.searchByOptions}
            isShowByRequired={isShowByRequired(flowType)}
            searchItems={this.searchItems}
            pageSize={this.pageSize}
            changePageSize={this.changePageSize}
            changeSearchBy={this.changeSearchBy}
            changeSearchValue={this.changeSearchValue}
            isGridViewIconRequired={true}
            handleViewChange={this.handleViewChange}
            changeSortDirection={this.changeSortDirection}
            searchPattern={searchPattern}
            searchValue={searchValue}
            searchParam={this.searchby}
            sortParam={this.sortParam}
            sortOrder={this.sortDirection}
          />)}
          <div className="displayFlex allOrdersTable">
            {isLoading && <Loader className="loaderCenter" active />}
            
            {!this.hideFilter && isFilterRequired && (
              <Filter
                filters={filters}
                isNoProducts={isNoProducts}
                appliedFilters={this.state.appliedFilters}
                defaultChecked={this.defaultChecked}
                clearFilters={false}
                filterCallback={this.filterCallback}
              />
            )}
            <div id="grid_width" className="full-width pl_data">
              {this.getListingHeader()}
              {isNoProducts ? (
                <div className="no_prdcts_display">
                  <h1>No Products to Display!</h1>
                </div>
              ) : (
                <>
                  {this.getListing()}
                  <span className="paginationButtom">
                    {this.state.paginationParams.totalPages > 0 &&
                      getPagination(
                        0,
                        parseInt(this.state?.paginationParams?.currentPage),
                        parseInt(this.state?.paginationParams?.totalPages),
                        this.onPageChange,
                        null,
                        1
                      )}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        }
      </>
      );
    } else {
      return (
        <>{isLoading && <Loader active />}</>
      )
    }
  }
}
const mapStateToProps = (state) => {
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    storeId: state.SessionReducer.storeId,
    userId: state.SessionReducer.UserId,
    userName: state.SessionReducer.address.userName,
    getprefer: state.preference.listData,
    multiStoreDetails: state.SessionReducer.multiStoreDetails,
    memberViewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    cartId: state.cart.orders?.currentCart?.cartId,
    carts: state.cart.orders?.allCarts,
    rdc: state.SessionReducer.rdc,
    marketDate: state.cart.orders.eventDate,
    qtyUpdate: state.cart.orders?.updateQty,
  };
};

export default connect(mapStateToProps, { viewAllCarts, updateQty  })(ItemListing);
