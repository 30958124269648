import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class VendorHeader extends Component {
  render() {
    return (
      <div>
        <div className='page-header vendorHeader'>Vendors</div>
        <Link to="/vendors" replace className='mobileLinkText'> Vendors </Link>
      </div>
    );
  }
}

export default VendorHeader;
