import React, { Component } from 'react';
import history from '../../history';
import utilities,{shouldHideCreditOnRAPage, showSavingValue, getPrefernceType} from '../../utils/utilities';
import './styles.css';
import { getRaCategories } from './actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';
import { MaskPrice } from '../common/MaskPrice';
import _ from 'lodash';

class RetailAssortmentLandingList extends Component {
  showAssortmentList = (assortment, endDate, backPath,isCtbAssortment,fromScanning,subCat) => {
    this.props.setBackButtonPath();
    assortment = {...assortment , backPath : backPath, isFlashsales: this.props.isFlashsales, isReunionAssortment:this.props.isReunionAssortment,
      ...(isCtbAssortment) ? {isCtbAssortment: isCtbAssortment,fromScanning: fromScanning, EnforceMissingAll:assortment?.EnforceMissingAll,
        ...(fromScanning && !_.isEmpty(subCat)? {subCat:subCat}: null )}: null
    }
    this.props.getRaCategories(assortment);
    history.push({
      pathname: '/assortment/' + assortment.number,
      state: {
        assortment: assortment,
        endDate: endDate,
        seleDept: assortment.department.name,
        seleCatg: assortment.categoryName,
        ...(isCtbAssortment) ? {missing: assortment?.EnforceMissingAll} : null
      }
    })
  }

  noProducts = (isFlashsales, isCtbAssortment) => { 
    return(
      <div className='no_items'>
        <h1>{isFlashsales ? 'No Flash Sales to display!' : isCtbAssortment? 'No CTB Assortments to display!':'No Retail Assortments to display!'}</h1>
      </div>
    );
  }

  render() {
    const getPrefer = getPrefernceType(this.props);
    return (
      <div className='ui stackable one column grid' id="listing_grid">
        {this.props.isNoProducts && this.noProducts(this.props.isFlashsales, this.props.isCtbAssortment)}
        {this.props.assortment.map((RaList) => {
          return (
            <RetailAssortmentList 
              assortment={RaList} 
              endDate={this.props.endDate}
              showAssortmentList={this.showAssortmentList}
              backPath = {this.props.backPath}
              isReunionAssortment = {this.props.isReunionAssortment}
              viewType ={this.props.viewType}
              getprefer = {getPrefer}
              isCtbAssortment = {this.props.isCtbAssortment}
              fromScanning = {this.props.fromScanning}
              subCat = {this.props.subCat}
            />
          )
        })}
      </div>
    );
  }
}

const RetailAssortmentList = ({ assortment, endDate, showAssortmentList, backPath, isReunionAssortment,viewType, getprefer, isCtbAssortment,fromScanning,subCat }) => {
  const showSavingLblAndVal = showSavingValue(viewType, getprefer);
  return (
    <div className='column' id='retail_landing'>
      <div className='ui segment card_item col_height' id='grid_segmentRetail'>
        <div className='assortments_card'>
          <div>
              <span className='assortment_headingList'>{assortment.description}</span>
              <span className='assortmentModelBox'>{isCtbAssortment ? '':'Assortment Model#: '}
              {!isCtbAssortment && (
                <>
                  <span className='model_name' onClick={() => showAssortmentList(assortment, endDate, backPath,isCtbAssortment,fromScanning,subCat)}>
                 <u>{assortment.modelNbr}</u>
               </span>
                </>
              )}
               <p>
             <span className='assortmentreunionRight' >POG: &ensp;
                 {assortment.planoNum ? assortment.planoNum :'N/A'}
                 </span>
                 <span >
                 <span>View Assortment: </span>
                 {assortment.planoNum ? 
                 <i className='file pdf icon large planogramexportie' id='export_btns'
                 onClick={() => utilities.openInNewTab(assortment.planoNum?parseInt(assortment.planoNum.length)>=9?
                 utilities.getplanogramPdf(assortment.planoNum):
                 utilities.getImpulsePdf(assortment.planoNum)
                 :'')} />
                 :'N/A'}
                 </span>
               </p>
	               </span>
              <span className='retail_model'>Dating: &ensp;
               {assortment.billingDateTerms > 0 ? assortment.billingDateTerms : 0 } Days
              </span>
          </div>
          <div className='dating_and_btnList'>
          {viewType === MEMBER_VIEW && <span>{utilities.getLabelsByType(viewType,isCtbAssortment ? "MARKDOWN": 'SAVING')}:&ensp;
            {isCtbAssortment ? 
            <span>
              {viewType === MEMBER_VIEW ?<span>
                    {assortment.savings}
                    </span>:
                    <MaskPrice
                     getPrefer = {getprefer}
                     viewType = {viewType}
                     fieldVal ={assortment.savings ? assortment.savings.split('%')[0] : ''}
                     field = "Saving Cost"
                    />}
            </span> : 
            viewType === MEMBER_VIEW && <span>
            {!utilities.isEmptyOrNullString(assortment.orderedModelNbr) ? 
              <span className='pl-card-field Assortmentfield credit_msg_color'>&ensp;
                    {'Not Eligible for Credit, Previously Purchased ' + assortment.orderedModelNbr + '.'}
              </span>
              : !utilities.isEmptyOrNullString(assortment.orderedDate) 
              ? <span className='pl-card-field Assortmentfield credit_msg_color'>&ensp;
                    {'Credit Redeemed '+ assortment.orderedDate}
                </span>
                : assortment.RAMarketFlag === 'Y' ?
                    <span className='credit_msg_color'>{'Save ' +assortment.savings + ' with $' + assortment.rebateAmount}</span>
                  : <span className='red_color'>
                    {assortment.savings}
                    </span>
            }</span>
            }
            </span>
            }
            {isCtbAssortment && (
              <>
              {/*<span style={{padding: '0 1rem'}}>
                {assortment?.IsMissingItem && parseInt(assortment?.IsMissingItem) === 0 ? 'Missing': 'All Items'}
            </span>*/}
              <span style={{padding: '0 1rem'}}>
                {assortment?.last_chance && (assortment.last_chance)}
              </span>
              </>
  
            )}
            {assortment.itemRestricted && isReunionAssortment &&
              <span className='retail_model Assortmentfield credit_msg_color'>Assortment is Restricted.
                {assortment.itemRestrictedAlternateNbr!=='' && 
                  <>&ensp;{assortment.itemRestrictedAlternateNbr} is an alternate assortment.</>
                }
              </span>            
            } 
            {isCtbAssortment && (
            <div>
              {(!_.isEmpty(assortment.Region) && assortment.Region !== "-" )&& <span>Region: {utilities.hyphenForNull(assortment.Region)}</span>}
              {!_.isEmpty(assortment.rdc_nbr)&&<span style={{marginLeft:'1.6rem'}}>RDC:{utilities.hyphenForNull(assortment.rdc_nbr)}</span>}
            </div>
          )}
            {/* Removed as per the business instruction */}
            {/* <span className='retail_model'>Market First Offered:</span>&emsp;
            <span>{assortment.Mkt_first_offered}</span> */}
            <button className='red fluid ui button' id='assortment-btn' 
                    onClick={() => showAssortmentList(assortment, endDate, backPath,isCtbAssortment,fromScanning,subCat)}>
                View / Order Assortment
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = (state,ownProps) =>{
  const getprefer = state.preference.listData;
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    ...ownProps,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    getprefer
  }
}

const mapDispatchToProps = (dispatch)=>{
  return Object.assign(
      { dispatch },
      bindActionCreators({getRaCategories},dispatch));
}

export default connect(mapStateToProps, mapDispatchToProps)(RetailAssortmentLandingList);
